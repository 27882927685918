import { RefObject, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

interface UseTextareaContentInput {
	opened?: boolean | null;
}

interface UseTextareaContentReturn {
	inputId: string;
	content: string;
	submitTop: number;
	submitRef: RefObject<HTMLDivElement>;
	setContent: (content: string) => void;
	submitHeight: number;
}

export default function useTextareaContent(
	{ opened }: UseTextareaContentInput = {},
): UseTextareaContentReturn {
	const inputId = useMemo(() => v4(), []);
	const submitRef = useRef<HTMLDivElement>(null);

	const [content, setContent] = useState('');
	const [touched, setTouched] = useState(false);
	const [submitTop, setSubmitTop] = useState(0);
	const [submitHeight, setSubmitHeight] = useState(0);

	useLayoutEffect(() => {
		if (!opened)
			return;

		const input = document.getElementById(inputId) as HTMLElement;
		const submit = submitRef.current as HTMLElement;

		setSubmitHeight(submit.clientHeight);
		setSubmitTop(touched
			? input.clientHeight - submit.clientHeight
			: input.clientHeight);

		if (!touched && !!content.trim()) {
			setTouched(true);
		}
	}, [inputId, content, touched, opened]);

	return {
		inputId,
		content,
		submitTop,
		submitRef,
		setContent,
		submitHeight,
	};
}
