import { ComponentType, useState } from 'react';
import { QuestionReplyFormProps, QuestionReplyFormPropsWithOpened } from './QuestionReplyForm';

export function withOpened(
	Component: ComponentType<QuestionReplyFormPropsWithOpened>,
): ComponentType<QuestionReplyFormProps> {
	return (props) => {
		const [opened, setOpened]
				= useState(false);

		return (
			<Component
				key={String(opened)}
				opened={opened}
				onOpened={setOpened}
				{...props}
			/>
		);
	};
}
