import { z } from 'zod';
import { i18n } from '../../../locales';

export const updatePasswordFormSchema = z.object({
	currentPassword: z.string()
		.min(1, i18n.t('settings.updatePassword.form.currentPassword.required')),
	newPassword: z.string()
		.min(8, i18n.t('settings.updatePassword.form.newPassword.minLength'))
		.max(20, i18n.t('settings.updatePassword.form.newPassword.maxLength'))
		.regex(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
			i18n.t('settings.updatePassword.form.newPassword.invalid'),
		),
	confirmPassword: z.string()
		.min(1, i18n.t('settings.updatePassword.form.confirmPassword.required')),
})
	.refine(({ currentPassword, newPassword }) => currentPassword !== newPassword, {
		message: i18n.t('settings.updatePassword.form.newPassword.same'),
		path: ['newPassword'],
	})
	.refine(({ newPassword, confirmPassword }) => newPassword === confirmPassword, {
		message: i18n.t('settings.updatePassword.form.confirmPassword.match'),
		path: ['confirmPassword'],
	});

export type UpdatePasswordFormInput = z.infer<typeof updatePasswordFormSchema>;

export const updatePasswordFormInitialValues: UpdatePasswordFormInput = {
	currentPassword: '',
	newPassword: '',
	confirmPassword: '',
};
