import { z } from 'zod';
import { i18n } from '../../../locales';

export const registerCompleteFormSchema	= z.object({
	username: z.string()
		.min(4, i18n.t('auth.registerComplete.form.username.minLength'))
		.max(15, i18n.t('auth.registerComplete.form.username.maxLength'))
		.regex(/^[a-z0-9]+$/, i18n.t('auth.registerComplete.form.username.invalid')),
	fullName: z.string()
		.min(1, i18n.t('auth.registerComplete.form.fullName.minLength'))
		.max(32, i18n.t('auth.registerComplete.form.fullName.maxLength')),
	password: z.string()
		.min(8, i18n.t('auth.registerComplete.form.password.minLength'))
		.max(20, i18n.t('auth.registerComplete.form.password.maxLength'))
		.regex(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
			i18n.t('auth.registerComplete.form.password.invalid'),
		),
	disclaimer: z.boolean()
		.refine(value => value, i18n.t('auth.registerComplete.form.disclaimer.required')),
});

export type RegisterCompleteFormInput = z.infer<typeof registerCompleteFormSchema>;

export const registerCompleteFormInitialValues: RegisterCompleteFormInput = {
	disclaimer: false,
	username: '',
	fullName: '',
	password: '',
};
