import { gql, TypedDocumentNode } from '@apollo/client';
import {
	SettingsClearRepliesMutation,
	SettingsClearRepliesMutationVariables,
} from './__generated__/SettingsClearRepliesMutation';

export const SettingsClearReplies: TypedDocumentNode<
	SettingsClearRepliesMutation,
	SettingsClearRepliesMutationVariables
> = gql`
	mutation SettingsClearReplies {
		clearReplies {
			success
			errors {
				code
			}
		}
	}
`;
