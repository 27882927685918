import { createEvent } from './helper';

export const replyCreatedEvent
		= createEvent<{ replyId: string }>('reply-created');

export const replyDeletedEvent
		= createEvent<{ replyId: string }>('reply-deleted');

export const questionDeletedEvent
		= createEvent<{ questionId: string }>('question-deleted');

export const allRepliesDeletedEvent
		= createEvent('all-replies-deleted');

export const allQuestionsDeletedEvent
		= createEvent('all-questions-deleted');
