import { Alert as BaseAlert, AlertProps as BaseAlertProps, Box, Progress } from '@mantine/core';

interface AlertProps
	extends BaseAlertProps {
	timeout: number;
	progress: number;
}

export default function Alert({ timeout, progress, ...alertProps }: AlertProps) {
	return (
		<Box>
			<BaseAlert {...alertProps} />
			<Progress
				ml="4px"
				mt="-8px"
				size="xs"
				value={progress}
				bg="transparent"
				w="calc(100% - 8px)"
				transitionDuration={timeout}
			/>
		</Box>
	);
}
