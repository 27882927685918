import { gql, TypedDocumentNode } from '@apollo/client';
import {
	GetQuestionsQuery,
	GetQuestionsQueryVariables,
	QuestionFieldsFragment,
} from './__generated__/QuestionsQuery';

export const QuestionFields: TypedDocumentNode<
	QuestionFieldsFragment
> = gql`
	fragment QuestionFields on Question {
		id
		content
		createdAt
		parent {
			id
			content
			createdAt
			parent {
				id
				content
				createdAt			
			}
		}
	}
`;

export const QuestionsQuery: TypedDocumentNode<
	GetQuestionsQuery,
	GetQuestionsQueryVariables
> = gql`
	query GetQuestions($sort: SortInput!, $pagination: PaginationInput!) {
		questions(sort: $sort, pagination: $pagination) {
			edges {
				...QuestionFields
			}
			pageInfo {
				total
				limit
				offset
			}
		}
	}
	
	${QuestionFields}
`;
