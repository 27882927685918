import { gql, TypedDocumentNode } from '@apollo/client';
import { GetRepliesQuery, GetRepliesQueryVariables, ReplyFieldsFragment } from './__generated__/RepliesQuery';

export const ReplyFields: TypedDocumentNode<ReplyFieldsFragment> = gql`
    fragment ReplyFields on Reply {
        id
        parent {
            id
            parent {
                id
                content
                createdAt
                parent {
                    id
                    content
                    createdAt
                }
            }
            content
            createdAt
        }
        content
        createdAt
    }
`;

export const RepliesQuery: TypedDocumentNode<GetRepliesQuery, GetRepliesQueryVariables> = gql`
    query GetReplies($username: String!, $pagination: PaginationInput!) {
        replies(username: $username, pagination: $pagination) {
            edges {
                ...ReplyFields
            }
            pageInfo {
                total
                limit
                offset
            }
        }
    }
    
    ${ReplyFields}
`;
