import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';
import Cookies from 'js-cookie';

import config from '../config';
import { ACCESS_TOKEN_KEY } from '../contexts/token';
import paths from '../routes/paths';

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		for (const err of graphQLErrors) {
			console.error(
				`[GRAPHQL ERROR]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
			);

			if (err.extensions?.code === 'UNAUTHENTICATED') {
				Cookies.remove(ACCESS_TOKEN_KEY);

				const homeURL = new URL(paths.home, window.location.origin);
				window.location.assign(homeURL);
				return;
			}
		}
	}

	if (networkError) {
		console.error(`[Network error]: ${networkError}`);
	}
});

const authLink = setContext((_, { headers }) => {
	const token = Cookies.get(ACCESS_TOKEN_KEY);

	return {
		headers: {
			...headers,
			'Apollo-Require-Preflight': 'true',
			'Authorization': token ? `Bearer ${token}` : '',
		},
	};
});

const httpLink = createUploadLink({
	uri: config.GRAPHQL_API_URI,
});

export default new ApolloClient({
	cache: new InMemoryCache(),
	link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
});
