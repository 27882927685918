import { Modal } from '@mantine/core';
import { Outlet } from 'react-router-dom';

export default function ModalLayout() {
	const fakeClose = () => {};

	return (
		<Modal
			opened
			radius={0}
			onClose={fakeClose}
			fullScreen
			withCloseButton={false}
		>
			<Outlet />
		</Modal>
	);
}
