import * as Sentry from '@sentry/react';
import Smartlook from 'smartlook-client';
import config from '../config';
import { Account } from '../types';

const analytics = {
	init: () => {
		try {
			Sentry.init({
				dsn: config.SENTRY_DSN,
				integrations: [
					Sentry.browserTracingIntegration(),
				],
				tracesSampleRate: 1.0,
				tracePropagationTargets: ['localhost', 'https://whispi.io'],
			});
		}
		catch (e) {
			console.error('[ANALYTICS ERROR] Failed to initialize Sentry', e);
		}

		try {
			Smartlook.init(config.SMARTLOOK_APP_ID);
		}
		catch (e) {
			console.error('[ANALYTICS ERROR] Failed to initialize Smartlook', e);
		}
	},
	identify: (account: Pick<Account, 'id' | 'username' | 'email' | 'fullName'>) => {
		try {
			const {
				id,
				email,
				username,
				fullName,
			} = account;

			Smartlook.identify(id, {
				email,
				fullName,
				username,
			});
		}
		catch (e) {
			console.error('[ANALYTICS ERROR] Failed to identify user with Smartlook', e);
		}
	},
};

export default analytics;
