import EventEmitter from 'eventemitter3';

export interface Event<T> {
	publish: (args: T) => void;
	subscribe: (cb: (args: T) => void) => void;
	unsubscribe: (cb: (args: T) => void) => void;
}

export function createEvent<T>(name: string): Event<T> {
	const emitter = new EventEmitter();

	return {
		publish: args => emitter.emit(name, args),
		subscribe: cb => emitter.on(name, cb),
		unsubscribe: cb => emitter.off(name, cb),
	};
}
