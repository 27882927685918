import { gql, TypedDocumentNode } from '@apollo/client';
import {
	AccountFieldsFragment,
	GetAccountQuery,
	GetAccountQueryVariables,
	GetAccountQuestionCountQuery,
	GetAccountQuestionCountQueryVariables,
} from './__generated__/AccountQuery';

export const AccountFields: TypedDocumentNode<AccountFieldsFragment> = gql`
	fragment AccountFields on Account {
		id
		email
		fullName
		username
		biography
		profileImage
	}
`;

export const AccountQuery: TypedDocumentNode<GetAccountQuery, GetAccountQueryVariables> = gql`
	query GetAccount {
		me {
			...AccountFields
		}
	}

	${AccountFields}
`;

export const AccountQuestionCountQuery: TypedDocumentNode<GetAccountQuestionCountQuery, GetAccountQuestionCountQueryVariables> = gql`
	query GetAccountQuestionCount {
		questions(pagination: { limit: 0, offset: 0 }, sort: { order: ASC }) {
			pageInfo {
				total
      }
		}
	}
`;
