import { gql, TypedDocumentNode } from '@apollo/client';
import {
	GetProfileQuery,
	GetProfileQueryVariables,
	GetProfileReplyCountQuery,
	GetProfileReplyCountQueryVariables,
	ProfileFieldsFragment,
} from './__generated__/ProfileQuery';

export const ProfileFields: TypedDocumentNode<
	ProfileFieldsFragment
> = gql`
	fragment ProfileFields on Profile {
		id
		fullName
		username
		biography
		profileImage
	}
`;

export const ProfileQuery: TypedDocumentNode<
	GetProfileQuery,
	GetProfileQueryVariables
> = gql`
	query GetProfile($username: String!) {
		profile(username: $username) {
			...ProfileFields
		}
	}
	
	${ProfileFields}
`;

export const ProfileReplyCountQuery: TypedDocumentNode<
	GetProfileReplyCountQuery,
	GetProfileReplyCountQueryVariables
> = gql`
	query GetProfileReplyCount($username: String!) {
		replies(username: $username, pagination: { limit: 0, offset: 0 }) {
			pageInfo {
				total
			}
		}
	}
`;
