import { Container, Stack } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { use100vh } from 'react-div-100vh';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer';

interface SimpleLayoutProps
	extends PropsWithChildren<any> {}

export default function SimpleLayout({ children }: SimpleLayoutProps) {
	const height = use100vh();

	return (
		<Container
			p="md"
			size="xs"
			display="flex"
			mih={height ?? '100vh'}
			style={{
				gap: '2rem',
				flexDirection: 'column',
			}}
		>
			<Stack
				w="100%"
				flex="1"
				justify="center"
			>
				{children || <Outlet />}
			</Stack>

			<Footer />
		</Container>
	);
}
