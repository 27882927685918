import { useMutation } from '@apollo/client';
import { Divider, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { UseFormReturn } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';
import analytics from '../../api/analytics';
import { useToken } from '../../contexts/token';
import { AuthErrorCode } from '../../types';
import paths from '../paths';
import RegisterCompleteForm, {
	RegisterCompleteFormInput,
} from './RegisterCompleteForm';
import { RegisterComplete as RegisterCompleteMutation } from './RegisterCompleteMutation';

export default function RegisterComplete() {
	const { t } = useTranslation();
	const { setToken } = useToken();
	const [searchParams] = useSearchParams();

	const token = searchParams.get('token');

	const [registerComplete] = useMutation(RegisterCompleteMutation);

	const handleSubmit = async (
		input: RegisterCompleteFormInput,
		form: UseFormReturn<RegisterCompleteFormInput>,
	) => {
		try {
			if (!token) {
				return;
			}

			const {
				username,
				password,
				fullName,
			} = input;

			const { data } = await registerComplete({
				variables: {
					input: {
						token,
						username,
						password,
						fullName,
					},
				},
			});

			const {
				errors,
				account,
				accessToken,
			} = data?.registerComplete || {};

			if (!accessToken || !account || !!errors?.length) {
				const usernameCannotBeUsedError
						= !!errors?.some(e => e.code === AuthErrorCode.UsernameCannotBeUsed);
				const userAlreadyRegisteredError
						= !!errors?.some(e => e.code === AuthErrorCode.UserAlreadyRegistered);

				if (usernameCannotBeUsedError) {
					form.setError('username', {
						type: 'manual',
						message: t('auth.registerComplete.form.username.cannotBeUsed'),
					});
					return;
				}

				if (userAlreadyRegisteredError) {
					form.setError('username', {
						type: 'manual',
						message: t('auth.registerComplete.form.username.alreadyExists'),
					});
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			setToken(accessToken, true);
			analytics.identify(account);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	if (!token?.trim()) {
		return <Navigate replace to={paths.register} />;
	}

	return (
		<Fragment>
			<Helmet>
				<title>
					{t('auth.registerComplete.pageTitle')}
				</title>
			</Helmet>

			<Stack gap="0.25rem">
				<Paper w={324} bg="dark.9" p="lg">
					<Stack gap="md">
						<Stack gap="md" align="center">
							<Text ta="center" lh="1" fz="2rem" ff="var(--ff-title)">
								{t('auth.registerComplete.title')}
							</Text>
							<Text fz="sm" fw="200" ta="center">
								{t('auth.registerComplete.subtitle')}
							</Text>
						</Stack>

						<Divider />

						<RegisterCompleteForm onSubmit={handleSubmit} />
					</Stack>
				</Paper>
			</Stack>
		</Fragment>
	);
}
