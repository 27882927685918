import { Fragment } from 'react';
import Question from '../../../components/Question';
import { useQuestions } from '../../../contexts/questions';

export default function QuestionsList() {
	const { questions } = useQuestions();

	return (
		<Fragment>

			{questions.map(question => (
				<Question
					key={question.id}
					{...question}
				/>
			))}
		</Fragment>
	);
}
