import { ErrorBoundary } from '@sentry/react';
import { PropsWithChildren } from 'react';
import ExceptionFallback from './ExceptionFallback';

interface ExceptionBoundaryProps
	extends PropsWithChildren<any> {}

export default function ExceptionBoundary({ children }: ExceptionBoundaryProps) {
	return (
		<ErrorBoundary fallback={<ExceptionFallback />}>
			{children}
		</ErrorBoundary>
	);
}
