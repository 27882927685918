import { createContext } from 'react';
import { PageInfo } from '../../types';
import { ReplyFieldsFragment } from './__generated__/RepliesQuery';

export interface RepliesContextType {
	error: boolean;
	replies: ReplyFieldsFragment[];
	loading: boolean;
	pageInfo: PageInfo;
}

export default createContext<RepliesContextType | null>(null);
