import auth from './auth.json';
import common from './common.json';
import home from './home.json';
import layout from './layout.json';
import legal from './legal.json';
import profile from './profile.json';
import questions from './questions.json';
import replies from './replies.json';
import settings from './settings.json';

export default {
	auth,
	home,
	legal,
	common,
	layout,
	profile,
	replies,
	settings,
	questions,
} as const;
