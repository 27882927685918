import { Button, Divider, Flex, Stack, Text } from '@mantine/core';
import { IconUserQuestion } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { useAccount } from '../../../contexts/account';
import { useProfile } from '../../../contexts/profile';
import paths from '../../../routes/paths';

interface ProfileControlProps
	extends PropsWithChildren<any> {}

export default function ProfileControl({
	children,
}: ProfileControlProps) {
	const { t } = useTranslation();
	const { profile, error, loading } = useProfile();
	const { account } = useAccount<'optional'>();

	const profileError = !loading && error && !profile;
	const profileNotFound = !loading && !error && !profile;

	if (profileNotFound || profileError) {
		return (
			<Stack
				w="100%"
				py="sm"
				pt="6rem"
				align="center"
				justify="center"
			>
				<IconUserQuestion size="5rem" stroke={1} />

				<Stack gap="md">
					<Stack gap="md" align="center">
						<Text ta="center" lh="1" fz="2rem" ff="var(--ff-title)">
							{profileNotFound
								? t('profile.notFound.title')
								: t('profile.error.title')}
						</Text>
						<Text fz="sm" fw="200" ta="center" px="6rem">
							{profileNotFound
								? t('profile.notFound.subtitle')
								: t('profile.error.subtitle')}
						</Text>
					</Stack>
					{account && (
						<>
							<Divider />

							<Flex
								w="100%"
								gap="xs"
								align="center"
								justify="center"
							>

								<Button
									size="xs"
									variant="light"
									component={Link}
									to={generatePath(paths.profile, { username: account.username })}
								>
									{profileNotFound
										? t('profile.notFound.return')
										: t('profile.error.return')}
								</Button>

							</Flex>
						</>
					)}
				</Stack>
			</Stack>
		);
	}

	return children;
}
