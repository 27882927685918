import { Flex, Skeleton } from '@mantine/core';

export default function ProfileLinksSkeleton() {
	return (
		<Flex gap="xs">
			<Skeleton w={82} h={30} />
			<Skeleton w={72} h={30} />
			<Skeleton w={72} h={30} />
		</Flex>
	);
}
