import { Divider, Paper, Stack } from '@mantine/core';
import { ReplyFieldsFragment } from '../../../contexts/replies/__generated__/RepliesQuery';
import ReplyContentText from './ReplyContentText';

interface ReplyContentProps
	extends Pick<ReplyFieldsFragment, 'content' | 'parent'> {}

export default function ReplyContent({
	parent,
	content,
}: ReplyContentProps) {
	return (
		<Paper p="xs">
			<Stack gap="0">
				<ReplyContentText
					fz="sm"
					fw="500"
					c="teal.2"
				>
					{parent.content}
				</ReplyContentText>

				<Divider mt="6" mb="8" />

				<ReplyContentText
					reply
					fz="sm"
					fw="300"
					c="white"
				>
					{content}
				</ReplyContentText>
			</Stack>
		</Paper>
	);
}
