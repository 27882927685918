import { gql, TypedDocumentNode } from '@apollo/client';
import { DeleteQuestionMutation, DeleteQuestionMutationVariables } from './__generated__/QuestionMutation';

export const DeleteQuestion: TypedDocumentNode<
	DeleteQuestionMutation,
	DeleteQuestionMutationVariables
> = gql`
	mutation DeleteQuestion($id: ID!) {
		deleteQuestion(id: $id) {
			success
			errors {
				code
			}
		}
	}
`;
