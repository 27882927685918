import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';

import { useForm, UseFormReturn } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import PasswordField from '../../../fields/PasswordField';

import {
	resetPasswordCompleteFormInitialValues,

	ResetPasswordCompleteFormInput,
	resetPasswordCompleteFormSchema,
} from './ResetPasswordCompleteForm.utils';

interface ResetPasswordCompleteFormProps {
	onSubmit: (
		values: ResetPasswordCompleteFormInput,
		form: UseFormReturn<ResetPasswordCompleteFormInput>
	) => Promise<void>;
}

export default function ResetPasswordCompleteForm({
	onSubmit,
}: ResetPasswordCompleteFormProps) {
	const { t } = useTranslation();
	const form = useForm<ResetPasswordCompleteFormInput>({
		resolver: zodResolver(resetPasswordCompleteFormSchema),
		defaultValues: resetPasswordCompleteFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(values => onSubmit(values, form));

	return (
		<form noValidate onSubmit={onSubmitHandler}>
			<Stack gap="sm">
				<PasswordField
					size="sm"
					maxLength={20}
					name="password"
					control={control}
					leftSection={<IconLock size={16} stroke={1.5} />}
					placeholder={t(
						'auth.resetPasswordComplete.form.password.placeholder',
					)}
				/>

				<Button
					type="submit"
					variant="light"
					loading={isSubmitting}
				>
					{t('auth.resetPasswordComplete.form.submit')}
				</Button>
			</Stack>
		</form>
	);
}
