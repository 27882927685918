import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Checkbox, Flex, Stack, Text } from '@mantine/core';
import { IconAt, IconKey } from '@tabler/icons-react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Link from '../../../components/Link';
import PasswordField from '../../../fields/PasswordField';

import TextField from '../../../fields/TextField';
import paths from '../../paths';
import {
	loginFormInitialValues,

	LoginFormInput,
	loginFormSchema,
} from './LoginForm.utils';

interface LoginFormProps {
	onSubmit: (
		values: LoginFormInput,
		form: UseFormReturn<LoginFormInput>
	) => Promise<void>;
}

export default function LoginForm({ onSubmit }: LoginFormProps) {
	const { t } = useTranslation();
	const form = useForm<LoginFormInput>({
		resolver: zodResolver(loginFormSchema),
		defaultValues: loginFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(values => onSubmit(values, form));

	return (
		<form noValidate onSubmit={onSubmitHandler}>
			<Stack gap="sm">
				<TextField
					size="sm"
					name="identifier"
					control={control}
					inputMode="email"
					autoComplete="username"
					leftSection={<IconAt size={16} stroke={1.5} />}
					placeholder={t('auth.login.form.identifier.placeholder')}
				/>

				<PasswordField
					size="sm"
					maxLength={20}
					name="password"
					control={control}
					autoComplete="current-password"
					leftSection={<IconKey size={16} stroke={1.5} />}
					placeholder={t('auth.login.form.password.placeholder')}
				/>

				<Stack gap="sm">
					<Flex justify="space-between" align="center">
						<Controller
							name="remember"
							control={control}
							render={({ field }) => (
								<Flex
									gap=".32rem"
									align="center"
									justify="center"
									component="label"
								>
									<Checkbox
										size="xs"
										checked={field.value}
										onChange={e => field.onChange(e.target.checked)}
									/>
									<Text fz="xs" fw="200">
										{t('auth.login.form.remember')}
									</Text>
								</Flex>
							)}
						/>

						<Text component={Link} to={paths.resetPassword} fw="200" fz="xs">
							{t('auth.login.form.forgotPassword')}
						</Text>
					</Flex>

					<Flex justify="flex-end">
						<Button
							type="submit"
							variant="light"
							loading={isSubmitting}
						>
							{t('auth.login.form.submit')}
						</Button>
					</Flex>
				</Stack>
			</Stack>
		</form>
	);
}
