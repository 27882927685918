import { IconEye, IconEyeOff } from '@tabler/icons-react';

interface ToggleIconProps {
	reveal: boolean;
}

export default function ToggleIcon({ reveal }: ToggleIconProps) {
	if (reveal) {
		return (
			<IconEyeOff
				size={16}
				stroke={1.5}
			/>
		);
	}

	return (
		<IconEye
			size={16}
			stroke={1.5}
		/>
	);
}
