import { useQuery } from '@apollo/client';

import { PropsWithChildren, useMemo } from 'react';

import {
	allRepliesDeletedEvent,
	replyCreatedEvent,
	replyDeletedEvent,
	useEvent,
} from '../../events';
import ProfileContext from './ProfileContext';
import { ProfileQuery, ProfileReplyCountQuery } from './ProfileQuery';

interface ProfileProviderProps extends PropsWithChildren<{
	username: string;
}> {}

export default function ProfileProvider({
	username,
	children,
}: ProfileProviderProps) {
	const { data, loading, error } = useQuery(ProfileQuery, {
		variables: { username },
	});

	const {
		data: replyCountData,
		loading: replyCountLoading,
		refetch: refetchReplyCount,
	} = useQuery(ProfileReplyCountQuery, {
		skip: !data?.profile,
		variables: { username },
	});

	useEvent(replyCreatedEvent, async () => {
		await refetchReplyCount();
	});

	useEvent(replyDeletedEvent, async () => {
		await refetchReplyCount();
	});

	useEvent(allRepliesDeletedEvent, async () => {
		await refetchReplyCount();
	});

	const contextValue = useMemo(
		() => ({
			error: !!error,
			profile: data?.profile || null,
			loading: loading || replyCountLoading,
			replyCount: replyCountData?.replies.pageInfo.total || 0,
		}),
		[
			error,
			loading,
			data?.profile,
			replyCountLoading,
			replyCountData?.replies.pageInfo.total,
		],
	);

	return (
		<ProfileContext.Provider value={contextValue}>
			{children}
		</ProfileContext.Provider>
	);
}
