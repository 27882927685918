import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Flex, Stack, Text, Textarea } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconChecks } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { REPLY_CREATED_TIMEOUT } from '../../../contexts/questions';
import { replyCreatedEvent } from '../../../events';
import TextareaField from '../../../fields/TextareaField';
import useTextareaContent from '../../../hooks/useTextareaContent';
import { Question, ReplyDeletePeriod, ReplyErrorCode } from '../../../types';
import Alert from '../../Alert';
import QuestionReplyDeletePeriodField from './QuestionReplyDeletePeriodField';
import { withOpened } from './QuestionReplyForm.hoc';
import {
	createReplyFormInitialValues,
	CreateReplyFormInput,
	createReplyFormSchema,
	MAX_CONTENT_LENGTH,
} from './QuestionReplyForm.utils';
import { CreateReply } from './QuestionReplyMutation';

export interface QuestionReplyFormProps
	extends Pick<Question, 'id'> {}

export interface QuestionReplyFormPropsWithOpened
	extends QuestionReplyFormProps {
	opened: boolean;
	onOpened: (opened: boolean) => void;
}

const QuestionReplyForm = withOpened(({
	id: questionId,
	opened,
	onOpened,
}: QuestionReplyFormPropsWithOpened) => {
	const {
		content,
		inputId,
		submitTop,
		submitRef,
		setContent,
		submitHeight,
	} = useTextareaContent({ opened });
	const { t } = useTranslation();

	const [success, setSuccess] = useState(false);
	const [progress, setProgress] = useState(0);

	const [createReply] = useMutation(CreateReply);
	const [defaultDeletePeriod] = useLocalStorage({
		key: 'app:reply-delete-period',
		defaultValue: ReplyDeletePeriod.Never,
		getInitialValueInEffect: false,
	});

	const form = useForm<CreateReplyFormInput>({
		resolver: zodResolver(createReplyFormSchema),
		defaultValues: {
			...createReplyFormInitialValues,
			deletePeriod: defaultDeletePeriod,
		},
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(async ({ content, deletePeriod }) => {
		try {
			const { data } = await createReply({
				variables: {
					input: {
						content: content.trim(),
						questionId,
						deletePeriod,
					},
				},
			});

			const {
				reply,
				errors,
			} = data?.createReply || {};

			if (errors?.length || !reply?.id) {
				const questionNotFound = !!errors?.some(
					e => e.code === ReplyErrorCode.QuestionNotFound,
				);

				if (questionNotFound) {
					notifications.show({
						message: t('questions.form.errors.questionNotFound'),
						color: 'red',
					});
					return;
				}

				const questionAlreadyReplied = !!errors?.some(
					e => e.code === ReplyErrorCode.QuestionAlreadyReplied,
				);

				if (questionAlreadyReplied) {
					notifications.show({
						message: t('questions.form.errors.questionAlreadyReplied'),
						color: 'red',
					});
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			setSuccess(true);
			replyCreatedEvent.publish({
				replyId: reply.id,
			});
			setTimeout(() => setProgress(100), 100);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	});

	function handleBlur() {
		if (!content.trim()) {
			onOpened(false);
		}
	}

	useEffect(() => {
		if (opened) {
			const textarea = document
				.getElementById(inputId) as HTMLElement | null;

			if (textarea) {
				textarea.focus();
			}
		}
	}, [inputId, opened]);

	return (
		<Stack gap="xs">
			<Divider />

			{!opened && (
				<Textarea
					rows={1.5}
					onFocus={() => onOpened(true)}
					placeholder={t('questions.form.content.placeholder')}
				/>
			)}

			{opened && (
				<form noValidate onSubmit={onSubmitHandler}>
					<Stack gap="xs">
						{!success && (
							<Stack gap="xs" pos="relative">
								<TextareaField
									autosize
									minRows={4}
									id={inputId}
									name="content"
									control={control}
									disabled={success}
									onBlur={handleBlur}
									maxLength={MAX_CONTENT_LENGTH}
									onChange={e => setContent(e.currentTarget.value)}
									styles={{ input: { paddingBottom: submitHeight } }}
									placeholder={t('questions.form.content.placeholder')}
								/>

								<Flex
									pb="5"
									px="6"
									w="100%"
									pos="absolute"
									ref={submitRef}
									top={submitTop}
									align="flex-end"
									justify="space-between"
								>
									<Text
										ml="1"
										mb="-2"
										fw="300"
										fz=".75rem"
										c={success ? 'gray.7' : undefined}
									>
										{`${content.length}/${MAX_CONTENT_LENGTH}`}
									</Text>

									<Flex gap="6">
										<Button
											size="xs"
											color="red.7"
											variant="subtle"
											disabled={success}
											onClick={() => onOpened(false)}
										>
											{t('questions.form.cancel')}
										</Button>

										<Button.Group>
											<Button
												size="xs"
												type="submit"
												color="teal.9"
												disabled={success}
												loading={isSubmitting}
											>
												{t('questions.form.submit')}
											</Button>

											<QuestionReplyDeletePeriodField
												name="deletePeriod"
												control={control}
												disabled={success}
											/>
										</Button.Group>
									</Flex>
								</Flex>
							</Stack>
						)}

						{success && (
							<Alert
								pb="lg"
								color="teal"
								variant="light"
								progress={progress}
								icon={<IconChecks />}
								timeout={REPLY_CREATED_TIMEOUT}
							>
								<Text fz="sm" c="teal" fw="normal">
									{t('questions.form.success')}
								</Text>
							</Alert>
						)}
					</Stack>
				</form>
			)}
		</Stack>
	);
});

export default QuestionReplyForm;
