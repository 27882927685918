import { gql, TypedDocumentNode } from '@apollo/client';
import { ResetPasswordMutation, ResetPasswordMutationVariables } from './__generated__/ResetPasswordMutation';

export const ResetPassword: TypedDocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables> = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
            errors {
                code
            }
        }
    }
`;
