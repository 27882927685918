import { gql, TypedDocumentNode } from '@apollo/client';
import { ClearQuestionsMutation, ClearQuestionsMutationVariables } from './__generated__/QuestionsMutation';

export const ClearQuestions: TypedDocumentNode<
	ClearQuestionsMutation,
	ClearQuestionsMutationVariables
> = gql`
	mutation ClearQuestions {
		clearQuestions {
			success
			errors {
				code
			}
		}
	}
`;
