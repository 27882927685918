import { gql, TypedDocumentNode } from '@apollo/client';
import { AccountFields } from '../../../contexts/account/AccountQuery';
import { ProfileFields } from '../../../contexts/profile/ProfileQuery';
import {
	UpdateInformationMutation,
	UpdateInformationMutationVariables,
} from './__generated__/SettingsInformationMutation';

export const UpdateInformation: TypedDocumentNode<
	UpdateInformationMutation,
	UpdateInformationMutationVariables
> = gql`
	mutation UpdateInformation($input: UpdateInformationInput!) {
		updateInformation(input: $input) {
			profile {
				...ProfileFields
			}
			account {
				...AccountFields
			}
			errors {
				code
			}
		}
	}
	
	${ProfileFields}
	${AccountFields}
`;
