import { Textarea, TextareaProps } from '@mantine/core';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface TextareaFieldProps<T extends FieldValues> extends
	Omit<TextareaProps, 'name'> {
	name: Path<T>;
	control: Control<T>;
}

export default function TextareaField<T extends FieldValues>({
	name,
	control,
	onChange,
	...inputProps
}: TextareaFieldProps<T>) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Textarea
					{...field}
					{...inputProps}
					error={error?.message}
					onChange={(event) => {
						field.onChange(event.target.value);
						if (onChange) {
							onChange(event);
						}
					}}
				/>
			)}
		/>
	);
};
