import { TextInput, TextInputProps } from '@mantine/core';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface TextFieldProps<T extends FieldValues> extends
	Omit<TextInputProps, 'name'> {
	name: Path<T>;
	control: Control<T>;
}

export default function TextField<T extends FieldValues>({
	name,
	control,
	...inputProps
}: TextFieldProps<T>) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextInput
					{...field}
					{...inputProps}
					error={error?.message}
				/>
			)}
		/>
	);
};
