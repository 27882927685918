import { notifications } from '@mantine/notifications';
import { i18n } from '../../../locales';

export function copyToClipboard(url: string) {
	if (navigator.clipboard && navigator.clipboard.writeText) {
		navigator.clipboard.writeText(url)
			.then(() =>
				notifications.show({
					message: i18n.t('common.share.copyToClipboard'),
					color: 'teal',
				}),
			)
			.catch(() => {
				fallbackCopyToClipboard(url);
			});
		return;
	}

	fallbackCopyToClipboard(url);
}

function fallbackCopyToClipboard(url: string) {
	const textArea = document.createElement('textarea');
	textArea.value = url;
	textArea.style.position = 'fixed';
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		const success = document.execCommand('copy');
		if (success) {
			notifications.show({
				color: 'teal',
				message: i18n.t('common.share.copyToClipboard'),
			});
		}
	}
	catch (e) {
		console.error(e);
	}

	document.body.removeChild(textArea);
}
