import { ComponentProps } from 'react';
import { Link as BaseLink } from 'react-router-dom';

interface LinkProps extends ComponentProps<typeof BaseLink> {
	preventScrollToTop?: boolean;
}

export default function Link({ preventScrollToTop = false, ...props }: LinkProps) {
	return (
		<BaseLink
			{...props}
			state={{ scroll: !preventScrollToTop }}
		/>
	);
}
