export default {
	home: '/',

	reply: '/@/:username/replies/:replyId',
	profile: '/@/:username',
	settings: '/@/:username/settings',
	questions: '/@/:username/questions',

	login: '/login',
	logout: '/logout',
	register: '/register',
	resetPassword: '/reset-password',
	registerComplete: '/register/complete',
	resetPasswordComplete: '/reset-password/complete',

	privacyPolicy: '/privacy-policy',
	termsOfService: '/terms-of-service',
} as const;
