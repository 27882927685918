import { Paper, Stack } from '@mantine/core';
import { useAccount } from '../../contexts/account';
import { useGuest } from '../../contexts/guest';
import { useProfile } from '../../contexts/profile';
import { ReplyFieldsFragment } from '../../contexts/replies/__generated__/RepliesQuery';
import ReplyContent from './ReplyContent';
import ReplyHeader from './ReplyHeader';
import ReplyParent from './ReplyParent';
import ReplyQuestionForm from './ReplyQuestionForm';

interface ReplyProps
	extends ReplyFieldsFragment {}

export default function Reply({
	id,
	parent,
	content,
	createdAt,
}: ReplyProps) {
	const { profile } = useProfile();
	const { account } = useAccount<'optional'>();

	const { replyIds } = useGuest();

	const isOwner = account?.id === profile?.id;

	return (
		<Paper w="100%" bg="dark.9" p="md">
			<Stack gap="xs">
				<ReplyHeader
					id={id}
					parent={parent}
					content={content}
					createdAt={createdAt}
				/>

				<Stack gap="xs">
					{parent.parent && (
						<ReplyParent {...parent.parent} />
					)}

					<ReplyContent parent={parent} content={content} />
					{replyIds.includes(id) && !isOwner && (
						<ReplyQuestionForm id={id} />
					)}
				</Stack>
			</Stack>
		</Paper>
	);
}
