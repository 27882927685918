import { Button, Divider, Paper, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../../contexts/account';
import { useProfile } from '../../../contexts/profile';
import SettingsClearQuestionsModal from './SettingsClearQuestionsModal';
import SettingsClearRepliesModal from './SettingsClearRepliesModal';
import SettingsDeleteAccountModal from './SettingsDeleteAccountModal';

export default function SettingsAccount() {
	const { t } = useTranslation();
	const { replyCount } = useProfile();
	const { questionCount } = useAccount<'optional'>();

	const [
		clearRepliesModalOpened,
		{ open: openClearRepliesModal, close: closeClearRepliesModal },
	] = useDisclosure();
	const [
		clearQuestionsModalOpened,
		{ open: openClearQuestionsModal, close: closeClearQuestionsModal },
	] = useDisclosure();
	const [
		deleteAccountModalOpened,
		{ open: openDeleteAccountModal, close: closeDeleteAccountModal },
	] = useDisclosure();

	return (
		<Fragment>
			<Paper w="100%" bg="dark.9" p="md">
				<Stack gap="sm">
					<Text lh="1" fz="1.5rem" ff="var(--ff-title)">
						{t('settings.accountSettings.title')}
					</Text>

					<Divider />

					<Paper bg="dark.8" p="sm">
						<Stack gap="sm" align="flex-start">
							<Text fz="sm">
								{t('settings.accountSettings.clearQuestions.description')}
							</Text>

							<Button
								size="xs"
								color="red"
								variant="light"
								disabled={!questionCount}
								onClick={openClearQuestionsModal}
							>
								{t('settings.accountSettings.clearQuestions.button')}
							</Button>
						</Stack>
					</Paper>

					<Paper bg="dark.8" p="sm">
						<Stack gap="sm" align="flex-start">
							<Text fz="sm">
								{t('settings.accountSettings.clearReplies.description')}
							</Text>

							<Button
								size="xs"
								color="red"
								variant="light"
								disabled={!replyCount}
								onClick={openClearRepliesModal}
							>
								{t('settings.accountSettings.clearReplies.button')}
							</Button>
						</Stack>
					</Paper>

					<Divider />

					<Paper bg="dark.8" p="sm">
						<Stack gap="sm" align="flex-start">
							<Text fz="sm">
								{t('settings.accountSettings.deleteAccount.description')}
							</Text>

							<Button
								size="xs"
								color="red"
								variant="light"
								onClick={openDeleteAccountModal}
							>
								{t('settings.accountSettings.deleteAccount.button')}
							</Button>
						</Stack>
					</Paper>
				</Stack>
			</Paper>

			{clearRepliesModalOpened && (
				<SettingsClearRepliesModal
					opened
					onRequestClose={closeClearRepliesModal}
				/>
			)}

			{clearQuestionsModalOpened && (
				<SettingsClearQuestionsModal
					opened
					onRequestClose={closeClearQuestionsModal}
				/>
			)}

			{deleteAccountModalOpened && (
				<SettingsDeleteAccountModal
					opened
					onRequestClose={closeDeleteAccountModal}
				/>
			)}
		</Fragment>
	);
}
