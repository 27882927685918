export function isEqual(a: any, b: any) {
	if (a === null || b === null)
		return a === b;

	if (a === undefined || b === undefined)
		return a === b;

	if (a === b)
		return true;

	if (typeof a !== 'object' || typeof b !== 'object')
		return false;

	if (Object.keys(a).length !== Object.keys(b).length)
		return false;

	for (const key in a) {
		if (!(key in b) || !isEqual(a[key], b[key]))
			return false;
	}

	return true;
}
