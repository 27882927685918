import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Flex, Stack, Text, Textarea } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconChecks } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGuest } from '../../../contexts/guest';
import { useProfile } from '../../../contexts/profile';
import TextareaField from '../../../fields/TextareaField';
import useTextareaContent from '../../../hooks/useTextareaContent';
import { QuestionErrorCode, Reply } from '../../../types';
import Alert from '../../Alert';
import { withOpened } from './ReplyQuestionForm.hoc';
import {
	createReplyQuestionFormInitialValues,
	CreateReplyQuestionFormInput,
	createReplyQuestionFormSchema,
	MAX_CONTENT_LENGTH,
} from './ReplyQuestionForm.utils';
import { CreateReplyQuestion } from './ReplyQuestionMutation';

export interface ReplyQuestionFormProps
	extends Pick<Reply, 'id'> {}

export interface ReplyQuestionFormPropsWithOpened
	extends ReplyQuestionFormProps {
	opened: boolean;
	onOpened: (opened: boolean) => void;
}

const QUESTION_CREATED_TIMEOUT = 6_000;

const ReplyQuestionForm = withOpened(({
	id: parentId,
	opened,
	onOpened,
}: ReplyQuestionFormPropsWithOpened) => {
	const {
		content,
		inputId,
		submitTop,
		submitRef,
		setContent,
		submitHeight,
	} = useTextareaContent({ opened });
	const { t } = useTranslation();

	const { profile } = useProfile();
	const { guestId, updateGuestId } = useGuest();

	const [success, setSuccess] = useState(false);
	const [progress, setProgress] = useState(0);

	const [createQuestion] = useMutation(CreateReplyQuestion);

	const form = useForm<CreateReplyQuestionFormInput>({
		resolver: zodResolver(createReplyQuestionFormSchema),
		defaultValues: createReplyQuestionFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(async ({ content }) => {
		try {
			if (!profile) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			const { data } = await createQuestion({
				variables: {
					userId: profile.id,
					guestId,
					content: content.trim(),
					parentId,
				},
			});

			const {
				errors,
				guestId: updatedGuestId,
			} = data?.createQuestion || {};

			if (errors?.length || !updatedGuestId) {
				const parentReplyNotFound = !!errors?.some(
					e => e.code === QuestionErrorCode.ParentReplyNotFound,
				);

				if (parentReplyNotFound) {
					notifications.show({
						message: t('replies.form.errors.replyNotFound'),
						color: 'red',
					});
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			setSuccess(true);

			setTimeout(() => setProgress(100), 100);
			setTimeout(() => updateGuestId(updatedGuestId), QUESTION_CREATED_TIMEOUT);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	});

	function handleBlur() {
		if (!content.trim()) {
			onOpened(false);
		}
	}

	useEffect(() => {
		if (opened) {
			const textarea = document
				.getElementById(inputId) as HTMLElement | null;

			if (textarea) {
				textarea.focus();
			}
		}
	}, [inputId, opened]);

	return (
		<Stack gap="xs">
			<Divider />

			{!opened && (
				<Textarea
					rows={1.5}
					onFocus={() => onOpened(true)}
					placeholder={t('replies.card.reply')}
				/>
			)}

			{opened && (
				<form noValidate onSubmit={onSubmitHandler}>
					<Stack gap="xs">
						{!success && (
							<Stack gap="xs" pos="relative">
								<TextareaField
									autosize
									minRows={4}
									id={inputId}
									name="content"
									control={control}
									disabled={success}
									onBlur={handleBlur}
									maxLength={MAX_CONTENT_LENGTH}
									onChange={e => setContent(e.currentTarget.value)}
									styles={{ input: { paddingBottom: submitHeight } }}
									placeholder={t('replies.form.content.placeholder')}
								/>

								<Flex
									pb="5"
									px="6"
									w="100%"
									pos="absolute"
									ref={submitRef}
									top={submitTop}
									align="flex-end"
									justify="space-between"
								>
									<Text
										ml="1"
										mb="-2"
										fw="300"
										fz=".75rem"
										c={success ? 'gray.7' : undefined}
									>
										{`${content.length}/${MAX_CONTENT_LENGTH}`}
									</Text>

									<Flex gap="6">
										<Button
											size="xs"
											color="red.7"
											variant="subtle"
											disabled={success}
											onClick={() => onOpened(false)}
										>
											{t('replies.form.cancel')}
										</Button>

										<Button
											size="xs"
											type="submit"
											color="teal.9"
											disabled={success}
											loading={isSubmitting}
										>
											{t('replies.form.submit')}
										</Button>
									</Flex>
								</Flex>
							</Stack>
						)}

						{success && (
							<Alert
								pb="lg"
								color="teal"
								variant="light"
								progress={progress}
								icon={<IconChecks />}
								timeout={QUESTION_CREATED_TIMEOUT}
							>
								<Text fz="sm" c="teal" fw="normal">
									{t('replies.form.success')}
								</Text>
							</Alert>
						)}
					</Stack>
				</form>
			)}
		</Stack>
	);
});

export default ReplyQuestionForm;
