import { useMutation } from '@apollo/client';
import { Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Fragment, useState } from 'react';

import { Helmet } from 'react-helmet';
import { UseFormReturn } from 'react-hook-form';

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthErrorCode } from '../../types';
import paths from '../paths';
import RegisterForm, { RegisterFormInput } from './RegisterForm';
import { Register as RegisterMutation } from './RegisterMutation';
import RegisterSuccess from './RegisterSuccess';

export default function Register() {
	const { t } = useTranslation();
	const [email, setEmail] = useState<string>();
	const [emailSent, setEmailSent] = useState(false);

	const [register] = useMutation(RegisterMutation);

	const handleSubmit = async (
		{ email }: RegisterFormInput,
		form: UseFormReturn<RegisterFormInput>,
	) => {
		try {
			const { data } = await register({
				variables: { input: { email } },
			});

			const { success, errors } = data?.register || {};

			if (!success || !!errors?.length) {
				if (errors?.some(e => e.code === AuthErrorCode.UserAlreadyRegistered)) {
					form.setError('email', {
						type: 'manual',
						message: t('auth.register.error.emailExists'),
					});
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			setEmail(email);
			setEmailSent(true);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	const handleReset = () => {
		setEmailSent(false);
		setEmail(undefined);
	};

	const handleResendEmail = async () => {
		try {
			if (!email) {
				return;
			}

			const { data } = await register({
				variables: { input: { email } },
			});

			const { success, errors } = data?.register || {};

			if (!success || !!errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			notifications.show({
				message: t('auth.register.emailResend.content'),
				title: t('auth.register.emailResend.title'),
				color: 'green',
			});
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	if (emailSent) {
		return (
			<RegisterSuccess
				onRequestReset={handleReset}
				onRequestResend={handleResendEmail}
			/>
		);
	}

	return (
		<Fragment>
			<Helmet>
				<title>{t('auth.register.pageTitle')}</title>
			</Helmet>

			<Stack gap="0.25rem">
				<Paper w={324} bg="dark.9" p="lg">
					<Stack gap="md">
						<Stack gap="md" align="center">
							<Text ta="center" lh="1" fz="2rem" ff="var(--ff-title)">
								{t('auth.register.title')}
							</Text>
							<Text fz="sm" fw="200" ta="center">
								{t('auth.register.subtitle')}
							</Text>
						</Stack>

						<Divider />

						<RegisterForm onSubmit={handleSubmit} />
					</Stack>
				</Paper>

				<Flex justify="center" pl="xs" gap=".25rem">
					<Trans
						i18nKey="auth.register.alreadyAccount"
						components={[
							<Text key="0" fz="xs" fw="200" />,
							<Text
								key="1"
								fz="xs"
								fw="500"
								c="teal.8"
								to={paths.login}
								component={Link}
							/>,
						]}
					/>
				</Flex>
			</Stack>
		</Fragment>
	);
}
