export default function generateInitials(name: string) {
	const letters = name.split(' ').map(part => part[0]);

	if (letters.length > 2) {
		return letters
			.slice(0, 2)
			.join('')
			.toUpperCase();
	}

	return letters
		.join('')
		.toUpperCase();
}
