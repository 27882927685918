import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAccount } from '../../contexts/account';
import paths from '../paths';

const NO_AUTH: string[] = [paths.home];
const REQUIRE_ANONYMOUS: string[] = [
	paths.login,
	paths.register,
	paths.resetPassword,
	paths.registerComplete,
	paths.resetPasswordComplete,
];

export const REDIRECT_SEARCH_PARAM = 'to';

export default function RequireAuth() {
	const location = useLocation();
	const { account } = useAccount<'optional'>();

	if (!account) {
		return (
			<Navigate
				to={
					REQUIRE_ANONYMOUS.includes(location.pathname) || NO_AUTH.includes(location.pathname)
						? paths.login
						: `${paths.login}?${REDIRECT_SEARCH_PARAM}=${location.pathname}`
				}
			/>
		);
	}

	return <Outlet />;
}
