import { Divider, Flex, Paper, Skeleton, Stack } from '@mantine/core';

export default function ProfileCardSkeleton() {
	return (
		<Paper w="100%" bg="dark.9" p="lg">
			<Stack w="100%">
				<Flex gap="sm">
					<Skeleton
						w="7rem"
						h="7rem"
						mih="7rem"
						miw="7rem"
						radius="md"
					/>

					<Stack w="100%" gap="xs" justify="space-between">
						<Stack gap="6px">
							<Skeleton h="2rem" w="80%" />
							<Skeleton h="1.5rem" w="40%" />
						</Stack>

						<Flex gap="xs">
							<Skeleton h="30px" w="78px" />
							<Skeleton h="30px" w="30px" />
						</Flex>
					</Stack>
				</Flex>

				<Divider />

				<Stack gap="4px">
					<Skeleton h="1rem" w="100%" />
					<Skeleton h="1rem" w="60%" />
				</Stack>
			</Stack>
		</Paper>
	);
}
