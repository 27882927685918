import { createContext } from 'react';
import { Profile } from '../../types';

export interface ProfileContextType {
	error: boolean;
	loading: boolean;
	profile?: Profile | null;
	replyCount: number;
}

export default createContext<ProfileContextType | null>(null);
