import { useContext } from 'react';
import { Account } from '../../../types';
import AccountContext from '../AccountContext';

type AccountFetchType = 'exact' | 'optional';

export default function useAccount<
	T extends AccountFetchType = 'exact',
>(): {
	questionCount: number;
	account: T extends 'optional' ? Account | null : Account;
} {
	const context = useContext(AccountContext);

	if (!context) {
		throw new Error('useAccount must be used within a AccountProvider');
	}

	return {
		account: context.account as any,
		questionCount: context.questionCount,
	};
}
