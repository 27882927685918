import { Button, Flex, Indicator } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { generatePath, Location, matchRoutes, useLocation } from 'react-router-dom';
import Link from '../../../components/Link';
import { useAccount } from '../../../contexts/account';
import { useProfile } from '../../../contexts/profile';
import paths from '../../../routes/paths';
import ProfileLinksSkeleton from './ProfileLinksSkeleton';

function variant(matched: boolean) {
	return matched ? 'outline' : 'light';
}

function matched(location: Location, path: string) {
	return !!matchRoutes([{ path }], location)?.length;
}

export default function ProfileLinks() {
	const { t } = useTranslation();
	const location = useLocation();

	const { profile, loading } = useProfile();
	const { account, questionCount } = useAccount<'optional'>();

	const username = profile?.username;
	const repliesMatched = matched(location, paths.profile);
	const settingsMatched = matched(location, paths.settings);
	const questionsMatched = matched(location, paths.questions);

	if (!account) {
		return null;
	}

	if (account && account.username !== username) {
		return null;
	}

	if (loading) {
		return <ProfileLinksSkeleton />;
	}

	return (
		<Flex gap="xs">
			<Button
				size="xs"
				component={Link}
				preventScrollToTop
				variant={variant(repliesMatched)}
				to={generatePath(paths.profile, { username: username ?? '' })}
			>
				{t('profile.replies')}
			</Button>
			<Indicator
				size={12}
				offset={2}
				withBorder
				disabled={!questionCount}

			>
				<Button
					size="xs"
					component={Link}
					preventScrollToTop
					variant={variant(questionsMatched)}
					to={generatePath(paths.questions, { username: username ?? '' })}
				>
					{t('profile.questions')}
				</Button>
			</Indicator>
			<Button
				size="xs"
				component={Link}
				preventScrollToTop
				variant={variant(settingsMatched)}
				to={generatePath(paths.settings, { username: username ?? '' })}
			>
				{t('profile.settings')}
			</Button>
		</Flex>
	);
}
