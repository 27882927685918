import { Avatar, Button, Divider, Flex, Image, Paper, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import Link from '../../components/Link';
import { useAccount } from '../../contexts/account';
import generateInitials from '../../helpers/generateInitials';
import paths from '../paths';

export default function Home() {
	const { t } = useTranslation();
	const { account } = useAccount<'optional'>();

	return (
		<Stack w="100%" py="6rem">
			<Stack gap="lg">
				<Stack
					gap="xl"
					align="center"
					justify="center"
				>
					<Stack gap="md" justify="center" align="center">
						<Image w="8rem" src="/assets/logo.svg" />
						<Text lh="1" fz="3rem" ff="var(--ff-title)">
							whispi.io
						</Text>
					</Stack>

					<Text fz="md" fw="300" ta="center">
						{t('home.description')}
					</Text>
				</Stack>

				<Divider />

				{account && (
					<Stack gap="xs" justify="center" align="center">
						<Paper bg="dark.9" p="xs" pr="md" radius="lg" w="fit-content">
							<Flex gap="6" justify="flex-start" align="center">
								<Avatar
									size="md"
									radius="md"
									src={account.profileImage}
								>
									{generateInitials(account.fullName)}
								</Avatar>

								<Stack gap="2">
									<Text
										lh="1"
										fz="1rem"
										c="teal.6"
										component={Link}
										ff="var(--ff-title)"
										to={generatePath(paths.profile, { username: account.username })}
									>
										{account.fullName}
									</Text>

									<Text
										fz="xs"
										c="gray"
										fw="200"
										component={Link}
										to={generatePath(paths.profile, { username: account.username })}
									>
										{`@${account.username}`}
									</Text>
								</Stack>
							</Flex>
						</Paper>

						<Flex gap="xs" justify="center">
							<Button
								size="xs"
								radius="md"
								color="teal.9"
								component={Link}
								state={{ scroll: true }}
								to={generatePath(paths.profile, { username: account.username })}
							>
								{t('home.goToAccount')}
							</Button>
							<Button
								size="xs"
								radius="md"
								variant="light"
								component={Link}
								to={paths.logout}
							>
								{t('home.logout')}
							</Button>
						</Flex>
					</Stack>
				)}

				{!account && (
					<Flex justify="center" align="center" gap="xs">
						<Button
							size="sm"
							variant="light"
							to={paths.login}
							component={Link}
							state={{ scroll: true }}
						>
							{t('home.login')}
						</Button>
						<Button
							size="sm"
							color="teal.9"
							component={Link}
							to={paths.register}
							state={{ scroll: true }}
						>
							{t('home.register')}
						</Button>
					</Flex>
				)}
			</Stack>
		</Stack>
	);
}
