import { useMutation } from '@apollo/client';
import { Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Fragment, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import { Link, Navigate, useSearchParams } from 'react-router-dom';
import paths from '../paths';
import ResetPasswordCompleteForm, {
	ResetPasswordCompleteFormInput,
} from './ResetPasswordCompleteForm';
import { ResetPasswordComplete as ResetPasswordCompleteMutation } from './ResetPasswordCompleteMutation';
import ResetPasswordCompleteSuccess from './ResetPasswordCompleteSuccess';

export default function ResetPasswordComplete() {
	const { t } = useTranslation();

	const [searchParams] = useSearchParams();
	const [success, setSuccess] = useState(false);

	const token = searchParams.get('token');

	const [resetPasswordComplete] = useMutation(ResetPasswordCompleteMutation);

	const handleSubmit = async ({ password }: ResetPasswordCompleteFormInput) => {
		try {
			if (!token) {
				return;
			}

			const { data } = await resetPasswordComplete({
				variables: {
					input: {
						token,
						password,
					},
				},
			});

			const {
				errors,
				success,
			} = data?.resetPasswordComplete || {};

			if (!success || !!errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			setSuccess(true);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	if (success) {
		return (
			<Fragment>
				<Helmet>
					<title>
						{t('auth.resetPasswordComplete.pageTitle')}
					</title>
				</Helmet>

				<ResetPasswordCompleteSuccess />
			</Fragment>
		);
	}

	if (!token?.trim()) {
		return <Navigate replace to={paths.resetPassword} />;
	}

	return (
		<Fragment>
			<Helmet>
				<title>
					{t('auth.resetPasswordComplete.pageTitle')}
				</title>
			</Helmet>

			<Stack gap="0.25rem">
				<Paper w={324} bg="dark.9" p="lg">
					<Stack gap="md">
						<Stack gap="md" align="center">
							<Text ta="center" lh="1" fz="2rem" ff="var(--ff-title)">
								{t('auth.resetPasswordComplete.title')}
							</Text>
							<Text fz="sm" fw="200" ta="center">
								{t('auth.resetPasswordComplete.subtitle')}
							</Text>
						</Stack>

						<Divider />

						<ResetPasswordCompleteForm onSubmit={handleSubmit} />
					</Stack>
				</Paper>

				<Flex justify="center" pl="xs" gap=".25rem">
					<Text key="0" fz="xs" fw="200">
						<Trans
							i18nKey="auth.resetPasswordComplete.backToLogin"
							components={[
								<Text
									key="0"
									fz="xs"
									fw="500"
									c="teal.8"
									to={paths.login}
									component={Link}
								/>,
							]}
						/>
					</Text>
				</Flex>
			</Stack>
		</Fragment>
	);
}
