import { NetworkStatus, useQuery } from '@apollo/client';

import { ReactNode, useMemo } from 'react';

import { allRepliesDeletedEvent, replyDeletedEvent, useEvent } from '../../events';
import { PageInfo, PaginationInput } from '../../types';
import { ReplyFieldsFragment } from './__generated__/RepliesQuery';
import RepliesContext, { RepliesContextType } from './RepliesContext';
import { RepliesQuery } from './RepliesQuery';

interface RepliesProviderProps extends PaginationInput {
	username: string;
	children: ReactNode | ((context: RepliesContextType) => ReactNode);
	onCompleted?: (
		edges: ReplyFieldsFragment[],
		pageInfo: PageInfo
	) => void;
}

const DEFAULT_PAGE_INFO: PageInfo = { total: 0, limit: 0, offset: 0 };

export default function RepliesProvider({
	limit,
	offset,
	children,
	username,
	onCompleted,
}: RepliesProviderProps) {
	const {
		data,
		error,
		refetch,
		loading,
		previousData,
		networkStatus,
	} = useQuery(RepliesQuery, {
		variables: {
			username,
			pagination: {
				limit,
				offset,
			},
		},
		fetchPolicy: 'cache-and-network',
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			const edges = data?.replies.edges;
			const pageInfo = data?.replies.pageInfo;

			if (!!edges && !!pageInfo && onCompleted) {
				onCompleted(edges, pageInfo);
			}
		},
	});

	useEvent(replyDeletedEvent, () => refetch());
	useEvent(allRepliesDeletedEvent, () => refetch());

	const contextValue = useMemo(
		() => {
			const mergedData = data || previousData;

			const pageInfo
						= mergedData?.replies.pageInfo || DEFAULT_PAGE_INFO;
			const replies
						= mergedData?.replies.edges || [];

			const isRefetch = networkStatus === NetworkStatus.refetch;

			return ({
				error: !!error,
				loading: loading && !isRefetch,
				replies,
				pageInfo,
			});
		},
		[data, error, loading, networkStatus, previousData],
	);

	return (
		<RepliesContext.Provider value={contextValue}>
			{typeof children === 'function'
				? children(contextValue)
				: children}
		</RepliesContext.Provider>
	);
}
