import { PasswordInput, PasswordInputProps } from '@mantine/core';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface PasswordFieldProps<T extends FieldValues> extends
	Omit<PasswordInputProps, 'name'> {
	name: Path<T>;
	control: Control<T>;
}

export default function PasswordField<T extends FieldValues>({
	name,
	control,
	...inputProps
}: PasswordFieldProps<T>) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<PasswordInput
					{...field}
					{...inputProps}
					error={error?.message}
				/>
			)}
		/>
	);
}
