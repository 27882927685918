export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  biography?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  profileImage?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type AccountError = {
  __typename?: 'AccountError';
  code: AccountErrorCode;
  message: Scalars['String']['output'];
};

export enum AccountErrorCode {
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  InvalidConstraints = 'INVALID_CONSTRAINTS',
  InvalidCurrentPassword = 'INVALID_CURRENT_PASSWORD',
  InvalidToken = 'INVALID_TOKEN',
  UsernameCannotBeUsed = 'USERNAME_CANNOT_BE_USED',
  UsernameChangeNotAllowed = 'USERNAME_CHANGE_NOT_ALLOWED'
}

export type AuthError = {
  __typename?: 'AuthError';
  code: AuthErrorCode;
  message: Scalars['String']['output'];
};

export enum AuthErrorCode {
  InvalidConstraints = 'INVALID_CONSTRAINTS',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidToken = 'INVALID_TOKEN',
  UsernameCannotBeUsed = 'USERNAME_CANNOT_BE_USED',
  UserAlreadyRegistered = 'USER_ALREADY_REGISTERED'
}

export type ClearQuestionsPayload = {
  __typename?: 'ClearQuestionsPayload';
  errors: Array<QuestionError>;
  success: Scalars['Boolean']['output'];
};

export type ClearRepliesPayload = {
  __typename?: 'ClearRepliesPayload';
  errors: Array<ReplyError>;
  success: Scalars['Boolean']['output'];
};

export type CreateQuestionInput = {
  content: Scalars['String']['input'];
  guestId?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateQuestionPayload = {
  __typename?: 'CreateQuestionPayload';
  errors: Array<QuestionError>;
  guestId?: Maybe<Scalars['ID']['output']>;
};

export type CreateReplyInput = {
  content: Scalars['String']['input'];
  deletePeriod: ReplyDeletePeriod;
  questionId: Scalars['ID']['input'];
};

export type CreateReplyPayload = {
  __typename?: 'CreateReplyPayload';
  errors: Array<ReplyError>;
  reply?: Maybe<Reply>;
};

export type DeleteAccountInput = {
  password: Scalars['String']['input'];
};

export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  errors: Array<AuthError>;
  success: Scalars['Boolean']['output'];
};

export type DeleteQuestionPayload = {
  __typename?: 'DeleteQuestionPayload';
  errors: Array<QuestionError>;
  success: Scalars['Boolean']['output'];
};

export type DeleteReplyPayload = {
  __typename?: 'DeleteReplyPayload';
  errors: Array<ReplyError>;
  success: Scalars['Boolean']['output'];
};

export type LoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  account?: Maybe<Account>;
  errors: Array<AuthError>;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  errors: Array<AuthError>;
  success: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  clearQuestions: ClearQuestionsPayload;
  clearReplies: ClearRepliesPayload;
  createQuestion: CreateQuestionPayload;
  createReply: CreateReplyPayload;
  deleteAccount: DeleteAccountPayload;
  deleteQuestion: DeleteQuestionPayload;
  deleteReply: DeleteReplyPayload;
  login: LoginPayload;
  logout: LogoutPayload;
  register: RegisterPayload;
  registerComplete: RegisterCompletePayload;
  resetPassword: ResetPasswordPayload;
  resetPasswordComplete: ResetPasswordCompletePayload;
  updateInformation: UpdateInformationPayload;
  updatePassword: UpdatePasswordPayload;
};


export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput;
};


export type MutationCreateReplyArgs = {
  input: CreateReplyInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteReplyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRegisterCompleteArgs = {
  input: RegisterCompleteInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordCompleteArgs = {
  input: ResetPasswordCompleteInput;
};


export type MutationUpdateInformationArgs = {
  input: UpdateInformationInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type Profile = {
  __typename?: 'Profile';
  biography?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  profileImage?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  guestReplyIds: Array<Scalars['ID']['output']>;
  me?: Maybe<Account>;
  profile?: Maybe<Profile>;
  questions: QuestionConnection;
  replies: ReplyConnection;
  reply?: Maybe<Reply>;
};


export type QueryGuestReplyIdsArgs = {
  guestId: Scalars['ID']['input'];
};


export type QueryProfileArgs = {
  username: Scalars['String']['input'];
};


export type QueryQuestionsArgs = {
  pagination: PaginationInput;
  sort: SortInput;
};


export type QueryRepliesArgs = {
  pagination: PaginationInput;
  username: Scalars['String']['input'];
};


export type QueryReplyArgs = {
  id: Scalars['ID']['input'];
};

export type Question = {
  __typename?: 'Question';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  parent?: Maybe<Reply>;
};

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  edges: Array<Question>;
  pageInfo: PageInfo;
};

export type QuestionError = {
  __typename?: 'QuestionError';
  code: QuestionErrorCode;
  message: Scalars['String']['output'];
};

export enum QuestionErrorCode {
  InvalidConstraints = 'INVALID_CONSTRAINTS',
  ParentReplyNotFound = 'PARENT_REPLY_NOT_FOUND',
  QuestionNotDeletable = 'QUESTION_NOT_DELETABLE',
  QuestionNotFound = 'QUESTION_NOT_FOUND',
  UserNotFound = 'USER_NOT_FOUND'
}

export type RegisterCompleteInput = {
  fullName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RegisterCompletePayload = {
  __typename?: 'RegisterCompletePayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  account?: Maybe<Account>;
  errors: Array<AuthError>;
};

export type RegisterInput = {
  email: Scalars['String']['input'];
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  errors: Array<AuthError>;
  success: Scalars['Boolean']['output'];
};

export type Reply = {
  __typename?: 'Reply';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  parent: Question;
  profile: Profile;
};

export type ReplyConnection = {
  __typename?: 'ReplyConnection';
  edges: Array<Reply>;
  pageInfo: PageInfo;
};

export enum ReplyDeletePeriod {
  EightHours = 'EIGHT_HOURS',
  FourHours = 'FOUR_HOURS',
  HalfHour = 'HALF_HOUR',
  Never = 'NEVER',
  OneDay = 'ONE_DAY',
  OneHour = 'ONE_HOUR',
  TwoHours = 'TWO_HOURS'
}

export type ReplyError = {
  __typename?: 'ReplyError';
  code: ReplyErrorCode;
  message: Scalars['String']['output'];
};

export enum ReplyErrorCode {
  InvalidConstraints = 'INVALID_CONSTRAINTS',
  QuestionAlreadyReplied = 'QUESTION_ALREADY_REPLIED',
  QuestionNotFound = 'QUESTION_NOT_FOUND',
  ReplyNotFound = 'REPLY_NOT_FOUND'
}

export type ResetPasswordCompleteInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordCompletePayload = {
  __typename?: 'ResetPasswordCompletePayload';
  errors: Array<AuthError>;
  success: Scalars['Boolean']['output'];
};

export type ResetPasswordInput = {
  email: Scalars['String']['input'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  errors: Array<AuthError>;
  success: Scalars['Boolean']['output'];
};

export type SortInput = {
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type UpdateInformationInput = {
  biography?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['Upload']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInformationPayload = {
  __typename?: 'UpdateInformationPayload';
  account?: Maybe<Account>;
  errors: Array<AccountError>;
  profile?: Maybe<Profile>;
};

export type UpdatePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  errors: Array<AccountError>;
};

export type UpdateUsernameInput = {
  username: Scalars['String']['input'];
};

export type UpdateUsernamePayload = {
  __typename?: 'UpdateUsernamePayload';
  account?: Maybe<Account>;
  errors: Array<AccountError>;
  profile?: Maybe<Profile>;
};
