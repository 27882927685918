import { gql, TypedDocumentNode } from '@apollo/client';

import { CreateReplyQuestionMutation, CreateReplyQuestionMutationVariables } from './__generated__/ReplyQuestionMutation';

export const CreateReplyQuestion: TypedDocumentNode<
	CreateReplyQuestionMutation,
	CreateReplyQuestionMutationVariables
> = gql`
	mutation CreateReplyQuestion($userId: ID!, $content: String!, $guestId: ID, $parentId: ID!) {
		createQuestion(input: { userId: $userId, content: $content, guestId: $guestId, parentId: $parentId }) {
			guestId
			errors {
				code
			}
		}
	}
`;
