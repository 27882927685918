import { ApolloProvider } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import { BrowserRouter } from 'react-router-dom';
import client from './api/client';
import { AccountProvider } from './contexts/account';
import { ExceptionBoundary } from './contexts/exception';

import { GuestProvider } from './contexts/guest';
import { ShareProvider } from './contexts/share';
import { TokenProvider } from './contexts/token';
import Routes from './routes/Routes';
import dark from './themes/dark';
import './locales';
import './globals.scss';

export default function App() {
	return (
		<ApolloProvider client={client}>
			<MantineProvider
				theme={dark}
				defaultColorScheme="dark"
			>
				<ExceptionBoundary>
					<TokenProvider>
						<ShareProvider>
							<AccountProvider>
								<GuestProvider>
									<BrowserRouter>
										<Routes />
									</BrowserRouter>
								</GuestProvider>
							</AccountProvider>
						</ShareProvider>
					</TokenProvider>
				</ExceptionBoundary>

				<Notifications position="top-right" />
			</MantineProvider>
		</ApolloProvider>
	);
}
