import { z } from 'zod';

import { i18n } from '../../../locales';

export const updateInformationFormSchema = z.object({
	username: z.string()
		.min(4, i18n.t('settings.updateInformation.form.username.minLength'))
		.max(15, i18n.t('settings.updateInformation.form.username.maxLength'))
		.regex(/^[a-z0-9]+$/, i18n.t('settings.updateInformation.form.username.invalid'))
		.optional()
		.nullable(),
	fullName: z.string()
		.min(1, i18n.t('settings.updateInformation.form.fullName.minLength'))
		.max(32, i18n.t('settings.updateInformation.form.fullName.maxLength'))
		.optional()
		.nullable(),
	biography: z.string()
		.min(3, i18n.t('settings.updateInformation.form.biography.minLength'))
		.max(150, i18n.t('settings.updateInformation.form.biography.maxLength'))
		.nullable()
		.optional()
		.or(z.literal('')),
	profileImage: z.instanceof(File)
		.refine(file => file.size <= 2 * 1024 * 1024, {
			message: i18n.t('settings.updateInformation.form.profileImage.size'),
		})
		.refine(file => ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type), {
			message: i18n.t('settings.updateInformation.form.profileImage.invalid'),
		})
		.optional()
		.nullable(),
});

export type UpdateInformationFormInput = z.infer<typeof updateInformationFormSchema>;

export function prepareUpdateInformationInput(
	initialValues: UpdateInformationFormInput,
	formValues: UpdateInformationFormInput,
) {
	return {
		username:
				formValues.username !== initialValues.username
					? formValues.username
					: undefined,
		fullName:
				formValues.fullName !== initialValues.fullName
					? formValues.fullName
					: undefined,
		biography:
				formValues.biography !== initialValues.biography
					? formValues.biography
					: undefined,
		profileImage:
				formValues.profileImage !== initialValues.profileImage
					? formValues.profileImage
					: undefined,
	};
}
