import { ComponentType } from 'react';
import { useAccount } from '../../../contexts/account';
import { useProfile } from '../../../contexts/profile';

export function withoutAccount(Component: ComponentType) {
	return () => {
		const { account } = useAccount();
		const { profile, loading } = useProfile();

		const isNonAuthenticatedProfile
				= account && account.username === profile?.username;

		if (loading || isNonAuthenticatedProfile) {
			return null;
		}

		return <Component />;
	};
}
