import { useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { isEqual } from './useFormState.utils';

export default function useFormState<T extends FieldValues>(
	form: UseFormReturn<T>,
) {
	const { control, handleSubmit, formState } = form;
	const { isSubmitting } = formState;

	const formRef = useRef<HTMLFormElement>(null);
	const initialValuesRef = useRef<T>(form.getValues());

	const [values, setValues] = useState<T>(form.getValues());
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		if (!formRef.current) {
			return;
		}

		const observer = new MutationObserver(() => {
			const dirty = !isEqual(form.getValues(), initialValuesRef.current);

			setIsDirty(dirty);
			setValues(form.getValues());
		});

		observer.observe(formRef.current, {
			subtree: true,
			childList: true,
			attributes: true,
			characterData: true,
		});

		return () => observer.disconnect();
	}, [form]);

	return {
		values,
		formRef,
		control,
		isDirty,
		handleSubmit,
		isSubmitting,
		initialValues: initialValuesRef.current,
	};
}
