import { useMutation } from '@apollo/client';
import { ActionIcon, Flex, Loader, Menu, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDots, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactTimeAgo from 'react-time-ago';
import { questionDeletedEvent } from '../../../events';
import { Question } from '../../../types';
import { DeleteQuestion } from './QuestionMutation';

interface QuestionHeaderProps
	extends Pick<Question, 'id' | 'createdAt'> {}

export default function QuestionHeader({
	id,
	createdAt,
}: QuestionHeaderProps) {
	const { t } = useTranslation();

	const [opened, setOpened] = useState(false);
	const [deleteQuestion, { loading }] = useMutation(DeleteQuestion);

	const handleDelete = async () => {
		try {
			const { data } = await deleteQuestion({
				variables: { id },
			});

			const {
				errors,
				success,
			} = data?.deleteQuestion || {};

			if (!success || errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			questionDeletedEvent
				.publish({ questionId: id });
			notifications.show({
				message: t('questions.card.menu.delete.success'),
				color: 'green',
			});

			setOpened(false);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	return (
		<Flex justify="space-between" align="center" mb="4">
			<Text fz="xs" component="p" ml="4">
				<Trans
					i18nKey="questions.card.createdAt"
					components={[
						<ReactTimeAgo
							key="time"
							date={new Date(createdAt)}
							updateInterval={[{ interval: 20, threshold: 60 }]}
						/>,
					]}
				/>
			</Text>

			<Menu
				width={152}
				offset={4}
				shadow="md"
				opened={opened}
				onChange={setOpened}
				position="bottom-end"
			>
				<Menu.Target>
					<ActionIcon
						color="gray.1"
						variant="subtle"
					>
						<IconDots size={14} stroke={1.5} />
					</ActionIcon>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Item
						color="red"
						onClick={handleDelete}
						closeMenuOnClick={false}
						leftSection={!loading ? <IconTrash size="14" /> : null}
					>
						{loading
							? (
								<Flex justify="center" align="center">
									<Loader size="12" color="red" />
								</Flex>
							)
							: t('questions.card.menu.delete.label')}
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</Flex>
	);
}
