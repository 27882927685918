import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Checkbox, Divider, Flex, Stack, Text } from '@mantine/core';

import {
	IconAt,
	IconLock,
	IconUser,
} from '@tabler/icons-react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';

import { Trans, useTranslation } from 'react-i18next';
import Link from '../../../components/Link';
import PasswordField from '../../../fields/PasswordField';
import TextField from '../../../fields/TextField';
import paths from '../../paths';
import {
	registerCompleteFormInitialValues,
	RegisterCompleteFormInput,
	registerCompleteFormSchema,
} from './RegisterCompleteForm.utils';

interface RegisterCompleteFormProps {
	onSubmit: (
		values: RegisterCompleteFormInput,
		form: UseFormReturn<RegisterCompleteFormInput>
	) => Promise<void>;
}

export default function RegisterCompleteForm({
	onSubmit,
}: RegisterCompleteFormProps) {
	const { t } = useTranslation();
	const form = useForm<RegisterCompleteFormInput>({
		resolver: zodResolver(registerCompleteFormSchema),
		defaultValues: registerCompleteFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(values => onSubmit(values, form));

	return (
		<form noValidate onSubmit={onSubmitHandler}>
			<Stack gap="sm">
				<TextField
					size="sm"
					maxLength={32}
					name="fullName"
					inputMode="text"
					control={control}
					leftSection={<IconUser size={16} stroke={1.5} />}
					placeholder={t('auth.registerComplete.form.fullName.placeholder')}
				/>

				<TextField
					size="sm"
					maxLength={15}
					name="username"
					inputMode="text"
					control={control}
					leftSection={<IconAt size={16} stroke={1.5} />}
					placeholder={t('auth.registerComplete.form.username.placeholder')}
				/>

				<Divider />

				<PasswordField
					size="sm"
					maxLength={20}
					name="password"
					control={control}
					leftSection={<IconLock size={16} stroke={1.5} />}
					placeholder={t('auth.registerComplete.form.password.placeholder')}
				/>

				<Controller
					name="disclaimer"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<Stack gap="4">
							<Flex
								gap=".32rem"
								justify="center"
								component="label"
								align="flex-start"
							>
								<Checkbox
									mt=".12rem"
									size="xs"
									checked={field.value}
									onChange={e => field.onChange(e.target.checked)}
								/>
								<Text fz="xs" fw="200">
									<Trans
										i18nKey="auth.registerComplete.form.disclaimer.label"
										components={[
											<Text
												key="0"
												fz="xs"
												fw="500"
												c="teal.8"
												target="_blank"
												component={Link}
												to={paths.termsOfService}
											/>,
											<Text
												key="1"
												fz="xs"
												fw="500"
												c="teal.8"
												target="_blank"
												component={Link}
												to={paths.privacyPolicy}
											/>,
										]}
									/>
								</Text>
							</Flex>

							{error && (
								<Text c="red.3" fz="xs" fw="400">
									{error.message}
								</Text>
							)}
						</Stack>
					)}
				/>

				<Flex justify="flex-end">
					<Button
						type="submit"
						variant="light"
						loading={isSubmitting}
					>
						{t('auth.registerComplete.form.submit')}
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
