import { useContext } from 'react';

import RepliesContext from '../RepliesContext';

export default function useReplies() {
	const context = useContext(RepliesContext);
	if (!context) {
		throw new Error('useReplies must be used within a RepliesProvider');
	}

	return context;
}
