import { Stack, Text } from '@mantine/core';
import { IconBug } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export default function ReplyFailed() {
	const { t } = useTranslation();
	return (
		<Stack
			w="100%"
			py="2rem"
			pt="2rem"
			align="center"
			justify="center"
		>
			<IconBug size="4rem" stroke={0.75} />

			<Stack gap="md">
				<Stack gap="xs" align="center">
					<Text ta="center" lh="1" fz="1.6rem" ff="var(--ff-title)">
						{t('replies.reply.error.title')}
					</Text>
					<Text fz="xs" fw="200" ta="center" px="6rem">
						{t('replies.reply.error.subtitle')}
					</Text>
				</Stack>
			</Stack>
		</Stack>
	);
}
