import { gql, TypedDocumentNode } from '@apollo/client';
import { AccountFields } from '../../contexts/account/AccountQuery';
import { RegisterCompleteMutation, RegisterCompleteMutationVariables } from './__generated__/RegisterCompleteMutation';

export const RegisterComplete: TypedDocumentNode<RegisterCompleteMutation, RegisterCompleteMutationVariables> = gql`
    mutation RegisterComplete($input: RegisterCompleteInput!) {
        registerComplete(input: $input) {
            account {
                ...AccountFields
            }
            accessToken
            errors {
                code
            }
        }
    }
    
    ${AccountFields}
`;
