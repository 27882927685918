import { Divider, Flex, Paper, Skeleton, Stack } from '@mantine/core';

export default function QuestionsSkeleton() {
	return (
		Array.from({ length: 12 }).map((_, index) => (
			<Paper
				p="md"
				w="100%"
				bg="dark.9"
				// eslint-disable-next-line react/no-array-index-key
				key={index}
			>
				<Stack gap="6">
					<Flex justify="space-between">
						<Skeleton h="15" w="140" />
						<Skeleton h="15" w="15" />
					</Flex>

					<Stack gap="xs">
						<Skeleton h="90" w="100%" />
						<Divider />
						<Skeleton h="36" w="100%" />
					</Stack>
				</Stack>
			</Paper>
		))
	);
}
