import { Button, Flex, Skeleton, Text } from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useReplies } from '../../../contexts/replies';

interface RepliesPaginationProps {
	onChange: (page: number) => void;
}

const SKELETON_PAGE_COUNT = 5;

export default function RepliesPagination(
	{ onChange }: RepliesPaginationProps,
) {
	const {
		loading,
		replies,
		pageInfo,
	} = useReplies();

	const recordCount = replies.length;
	const page = Math.floor(pageInfo.offset / pageInfo.limit) + 1;
	const total = Math.ceil(pageInfo.total / pageInfo.limit);

	const { range, active, previous, next, setPage } = usePagination({
		page,
		total,
		onChange,
	});

	const isNextDisabled = active === total || loading || recordCount === 0;
	const isPreviousDisabled = active === 1 || loading;

	return (
		<Flex
			gap="xs"
			align="center"
			justify="center"
		>
			<Button
				w={30}
				h={30}
				px={0}
				onClick={() => isPreviousDisabled || previous()}
				variant={isPreviousDisabled ? 'transparent' : 'subtle'}
				style={{ cursor: isPreviousDisabled ? 'default' : 'pointer' }}
			>
				<IconChevronLeft
					size={14}
					color={isPreviousDisabled ? 'gray' : undefined}
				/>
			</Button>

			{loading
				? Array.from({ length: SKELETON_PAGE_COUNT }, (_, index) => (
					<Skeleton key={`pagination-${index}`} w={32} h={32} />
				))
				: range.map((number, index) => {
					if (number === 'dots') {
						return (
							<Text
								w={30}
								fw={300}
								c="indigo"
								ta="center"
								mt="-.5rem"
								key={`pagination-${index}-dots`} // eslint-disable-line react/no-array-index-key
							>
								...
							</Text>
						);
					}

					return (
						<Button
							h={30}
							px={4}
							miw={30}
							fw={500}
							size="xs"
							onClick={() => setPage(number)}
							key={`pagination-${index}-${number}`} // eslint-disable-line react/no-array-index-key
							variant={number === active ? 'light' : 'subtle'}
						>
							{number}
						</Button>
					);
				})}

			<Button
				w={32}
				h={32}
				px={0}
				onClick={() => isNextDisabled || next()}
				variant={isNextDisabled ? 'transparent' : 'subtle'}
				style={{ cursor: isNextDisabled ? 'default' : 'pointer' }}
			>
				<IconChevronRight
					size={14}
					color={isNextDisabled ? 'gray' : undefined}
				/>
			</Button>
		</Flex>
	);
}
