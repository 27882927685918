import { Button, Divider, Paper, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import paths from '../../paths';

export default function ResetPasswordCompleteSuccess() {
	const { t } = useTranslation();

	return (
		<Paper w={324} bg="dark.9" p="lg">
			<Stack gap="md">
				<Stack gap="md" align="center">
					<Text ta="center" lh="1" fz="2rem" ff="var(--ff-title)">
						{t(
							'auth.resetPasswordComplete.resetPasswordCompleteSuccess.title',
						)}
					</Text>
					<Text fz="sm" fw="200" ta="center">
						{t(
							'auth.resetPasswordComplete.resetPasswordCompleteSuccess.content',
						)}
					</Text>
				</Stack>

				<Divider />

				<Button
					variant="light"
					to={paths.login}
					component={Link}
				>
					{t('auth.resetPasswordComplete.resetPasswordCompleteSuccess.login')}
				</Button>
			</Stack>
		</Paper>
	);
}
