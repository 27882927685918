import { Divider, Image, Stack, Text } from '@mantine/core';

import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Link from '../../components/Link';
import paths from '../paths';

export default function PrivacyPolicy() {
	const { t } = useTranslation();

	return (
		<Fragment>
			<Helmet>
				<title>
					{t('legal.privacyPolicy.pageTitle')}
				</title>
			</Helmet>

			<Stack w="100%">
				<Stack my="xl">
					<Stack gap="md">
						<Stack gap="md">
							<Text fz="2rem" ff="var(--ff-title)" c="teal.6">
								{t('legal.privacyPolicy.title')}
							</Text>
							<Text fz="sm" fw="300">
								{t('legal.privacyPolicy.lastUpdated')}
							</Text>
						</Stack>

						<Divider />

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.introduction.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.introduction.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.informationWeCollect.title')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.useOfInformation.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.useOfInformation.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.informationSharing.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.informationSharing.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.dataSecurity.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.dataSecurity.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.anonymousQuestions.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.anonymousQuestions.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.userRights.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.userRights.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.childrenPrivacy.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.childrenPrivacy.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.internationalDataTransfer.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.internationalDataTransfer.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.changes.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.changes.content')}
							</Text>
						</Stack>

						<Stack gap="xs">
							<Text fz="1.5rem" ff="var(--ff-title)">
								{t('legal.privacyPolicy.contact.title')}
							</Text>
							<Text fz="sm">
								{t('legal.privacyPolicy.contact.content')}
							</Text>
						</Stack>

						<Divider />

						<Text fz="sm">
							{t('legal.privacyPolicy.ending')}
						</Text>
					</Stack>

					<Stack mt="4rem" gap="md" justify="center" align="center">
						<Image w="5rem" src="/assets/logo.svg" />
						<Text
							lh="1"
							fz="2rem"
							to={paths.home}
							component={Link}
							ff="var(--ff-title)"
						>
							whispi.io
						</Text>
					</Stack>
				</Stack>
			</Stack>
		</Fragment>
	);
}
