import { gql, TypedDocumentNode } from '@apollo/client';
import { UpdatePasswordMutation, UpdatePasswordMutationVariables } from './__generated__/SettingsPasswordMutation';

export const UpdatePassword: TypedDocumentNode<
	UpdatePasswordMutation,
	UpdatePasswordMutationVariables
> = gql`
	mutation UpdatePassword($input: UpdatePasswordInput!) {
		updatePassword(input: $input) {
			accessToken
			errors {
				code
			}
		}
	}
`;
