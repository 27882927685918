import i18n from 'i18next';
import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en';
import tr from 'javascript-time-ago/locale/tr';

import { initReactI18next } from 'react-i18next';
import resources from './resources';

const LANGUAGE_KEY = 'app:language';

export const defaultNS = 'translation';
export const defaultLanguage = 'en';

const userLanguage = (() => {
	const stored = localStorage.getItem(LANGUAGE_KEY);

	if (stored)
		return stored;

	if (typeof navigator === 'undefined')
		return defaultLanguage;

	return (navigator.language || navigator.languages[0] || 'en')
		.split('-')[0];
})();

const defaultLocale = userLanguage === 'tr' ? 'tr' : 'en';

TimeAgo.addLocale(en);
TimeAgo.addLocale(tr);

TimeAgo.setDefaultLocale(defaultLocale);

i18n.use(initReactI18next).init({
	lng: userLanguage,
	resources,
	defaultNS,
	fallbackLng: defaultLanguage,
	interpolation: {
		escapeValue: false,
	},
});

i18n.on('languageChanged', (lang: string) => {
	localStorage.setItem(LANGUAGE_KEY, lang);
	TimeAgo.setDefaultLocale(lang === 'tr' ? 'tr' : 'en');
});

export default i18n;
