import { generatePath, Navigate, Outlet } from 'react-router-dom';

import { useAccount } from '../../contexts/account';
import paths from '../paths';

export default function RequireAnonymous() {
	const { account } = useAccount<'optional'>();

	if (account) {
		return (
			<Navigate
				replace
				to={generatePath(paths.profile, { username: account.username })}
			/>
		);
	}

	return <Outlet />;
}
