import { NetworkStatus, useQuery } from '@apollo/client';

import { ReactNode, useMemo, useRef } from 'react';

import { allQuestionsDeletedEvent, questionDeletedEvent, replyCreatedEvent, useEvent } from '../../events';
import { PageInfo, PaginationInput, SortInput } from '../../types';
import { QuestionFieldsFragment } from './__generated__/QuestionsQuery';
import QuestionsContext, { QuestionsContextType } from './QuestionsContext';
import { QuestionsQuery } from './QuestionsQuery';

interface QuestionsProviderProps extends PaginationInput, SortInput {
	children: ReactNode | ((context: QuestionsContextType) => ReactNode);
	onCompleted?: (
		edges: QuestionFieldsFragment[],
		pageInfo: PageInfo
	) => void;
}

const POLL_INTERVAL = 10_000;
const DEFAULT_PAGE_INFO: PageInfo = { total: 0, limit: 0, offset: 0 };
export const REPLY_CREATED_TIMEOUT = 6_000;

export default function QuestionsProvider({
	order,
	limit,
	offset,
	children,
	onCompleted,
}: QuestionsProviderProps) {
	const skipPollAttempt = useRef(false);

	const {
		data,
		error,
		loading,
		refetch,
		previousData,
		networkStatus,
	} = useQuery(QuestionsQuery, {
		variables: {
			sort: { order },
			pagination: { limit, offset },
		},
		fetchPolicy: 'cache-and-network',
		pollInterval: POLL_INTERVAL,
		notifyOnNetworkStatusChange: true,
		skipPollAttempt: () => {
			return skipPollAttempt.current;
		},
		onCompleted: (data) => {
			const edges = data?.questions.edges;
			const pageInfo = data?.questions.pageInfo;

			if (!!edges && !!pageInfo && onCompleted) {
				onCompleted(edges, pageInfo);
			}
		},
	});

	useEvent(allQuestionsDeletedEvent, () => refetch());
	useEvent(questionDeletedEvent, () => refetch());
	useEvent(replyCreatedEvent, () => {
		skipPollAttempt.current = true;

		setTimeout(async () => {
			await refetch();
			skipPollAttempt.current = false;
		}, REPLY_CREATED_TIMEOUT);
	});

	const contextValue = useMemo(
		() => {
			const mergedData = data || previousData;

			const pageInfo
					= mergedData?.questions.pageInfo || DEFAULT_PAGE_INFO;
			const questions
					= mergedData?.questions.edges || [];

			const isPoll = networkStatus === NetworkStatus.poll;
			const isRefetch = networkStatus === NetworkStatus.refetch;

			return ({
				error: !!error,
				loading: loading && !isPoll && !isRefetch,
				pageInfo,
				questions,
			});
		},
		[data, error, loading, networkStatus, previousData],
	);

	return (
		<QuestionsContext.Provider value={contextValue}>
			{typeof children === 'function'
				? children(contextValue)
				: children}
		</QuestionsContext.Provider>
	);
}
