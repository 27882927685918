import { z } from 'zod';
import { i18n } from '../../../locales';

export const MAX_CONTENT_LENGTH = 3000;

export const createReplyQuestionFormSchema = z.object({
	content: z.string()
		.min(3, i18n.t('replies.form.content.minLength'))
		.max(
			MAX_CONTENT_LENGTH,
			i18n.t('replies.form.content.maxLength', { maxLength: MAX_CONTENT_LENGTH }),
		),
});

export type CreateReplyQuestionFormInput = z.infer<typeof createReplyQuestionFormSchema>;

export const createReplyQuestionFormInitialValues: CreateReplyQuestionFormInput = {
	content: '',
};
