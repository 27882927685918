import { gql, TypedDocumentNode } from '@apollo/client';
import { LogoutMutation, LogoutMutationVariables } from './__generated__/LogoutMutation';

export const Logout: TypedDocumentNode<LogoutMutation, LogoutMutationVariables> = gql`
    mutation Logout {
        logout {
						success
						errors {
								code
            }
        }
    }

`;
