import { gql, TypedDocumentNode } from '@apollo/client';
import {
	SettingsClearQuestionsMutation,
	SettingsClearQuestionsMutationVariables,
} from './__generated__/SettingsClearQuestionsMutation';

export const SettingsClearQuestions: TypedDocumentNode<
	SettingsClearQuestionsMutation,
	SettingsClearQuestionsMutationVariables
> = gql`
	mutation SettingsClearQuestions {
		clearQuestions {
			success
			errors {
				code
			}
		}
	}
`;
