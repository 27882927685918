import { z } from 'zod';
import { i18n } from '../../../../locales';

export const deleteAccountFormSchema = z.object({
	password: z.string()
		.min(1, i18n.t('settings.accountSettings.deleteAccount.modal.form.password.required'))
		.max(20),
});

export type DeleteAccountFormInput = z.infer<typeof deleteAccountFormSchema>;

export const deleteAccountFormInitialValues: DeleteAccountFormInput = {
	password: '',
};
