import { gql, TypedDocumentNode } from '@apollo/client';
import {
	SettingsDeleteAccountMutation,
	SettingsDeleteAccountMutationVariables,
} from './__generated__/SettingsDeleteAccountMutation';

export const SettingsDeleteAccount: TypedDocumentNode<
	SettingsDeleteAccountMutation,
	SettingsDeleteAccountMutationVariables
> = gql`
	mutation SettingsDeleteAccount($password: String!) {
		deleteAccount(input: { password: $password }) {
			success
			errors {
				code
			}
		}
	}
`;
