import { gql, TypedDocumentNode } from '@apollo/client';
import { ReplyFields } from '../../contexts/replies/RepliesQuery';
import { GetReplyQuery, GetReplyQueryVariables } from './__generated__/ReplyQuery';

export const ReplyQuery: TypedDocumentNode<GetReplyQuery, GetReplyQueryVariables> = gql`
	query GetReply($replyId: ID!) {
		reply(id: $replyId) {
			...ReplyFields
		}
	}
	
	${ReplyFields}
`;
