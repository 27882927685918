import { Container, Stack, Text } from '@mantine/core';
import { use100vh } from 'react-div-100vh';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer';
import Link from '../../components/Link';
import paths from '../../routes/paths';

export default function AuthLayout() {
	const height = use100vh();

	return (
		<Container
			p="md"
			size="xs"
			display="flex"
			mih={height ?? '100vh'}
			style={{
				gap: '2rem',
				flexDirection: 'column',
			}}
		>
			<Stack
				w="100%"
				flex="1"
				py="6rem"
				align="center"
				justify="center"
			>
				<Stack
					w="100%"
					gap="lg"
					align="center"
					justify="center"
				>
					<Text
						lh="1"
						fz="2rem"
						to={paths.home}
						component={Link}
						ff="var(--ff-title)"
					>
						whispi.io
					</Text>

					<Outlet />
				</Stack>
			</Stack>

			<Footer />
		</Container>
	);
}
