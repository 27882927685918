import { Button, Divider, Flex, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconList, IconPlaylistX, IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import { Fragment, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuestions } from '../../../contexts/questions';
import { SortOrder } from '../../../types';
import QuestionsClearModal from './QuestionsClearModal';

interface QuestionsLayoutProps
	extends PropsWithChildren<{
		order: SortOrder;
		onOrderChange: (order: SortOrder) => void;
	}> {}

export default function QuestionsLayout({
	order,
	children,
	onOrderChange,
}: QuestionsLayoutProps) {
	const { t } = useTranslation();
	const { error, pageInfo } = useQuestions();

	const [opened, { open, close }] = useDisclosure();
	const deleteAllDisabled = pageInfo.total === 0 || error;

	return (
		<Fragment>
			<Flex justify="space-between">
				<Menu shadow="md" position="bottom-start" width={180}>
					<Menu.Target>
						<Button
							size="xs"
							variant="light"
							rightSection={<IconList size={16} />}
						>
							{t('questions.settings.order.title')}
						</Button>
					</Menu.Target>

					<Menu.Dropdown>
						<Menu.Item
							onClick={() => onOrderChange(SortOrder.Asc)}
							leftSection={<IconSortAscending size="14" />}
							color={order === SortOrder.Asc ? 'teal' : 'gray'}
						>
							{t('questions.settings.order.menu.asc')}
						</Menu.Item>

						<Menu.Item
							onClick={() => onOrderChange(SortOrder.Desc)}
							leftSection={<IconSortDescending size="14" />}
							color={order === SortOrder.Desc ? 'teal' : 'gray'}
						>
							{t('questions.settings.order.menu.desc')}
						</Menu.Item>
					</Menu.Dropdown>
				</Menu>

				<Button
					size="xs"
					color="red.9"
					onClick={open}
					variant="light"
					disabled={deleteAllDisabled}
					rightSection={<IconPlaylistX size={18} />}
				>
					{t('questions.settings.deleteAll.title')}
				</Button>
			</Flex>

			<Divider />

			{children}

			{opened && (
				<QuestionsClearModal
					opened
					onRequestClose={close}
				/>
			)}
		</Fragment>
	);
}
