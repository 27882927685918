import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack } from '@mantine/core';
import { IconAt } from '@tabler/icons-react';

import { useForm, UseFormReturn } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import TextField from '../../../fields/TextField';
import {
	resetPasswordFormInitialValues,
	ResetPasswordFormInput,
	resetPasswordFormSchema,
} from './ResetPasswordForm.utils';

interface ResetPasswordFormProps {
	onSubmit: (
		values: ResetPasswordFormInput,
		form: UseFormReturn<ResetPasswordFormInput>
	) => Promise<void>;
}

export default function ResetPasswordForm({
	onSubmit,
}: ResetPasswordFormProps) {
	const { t } = useTranslation();
	const form = useForm<ResetPasswordFormInput>({
		resolver: zodResolver(resetPasswordFormSchema),
		defaultValues: resetPasswordFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(values => onSubmit(values, form));

	return (
		<form noValidate onSubmit={onSubmitHandler}>
			<Stack gap="sm">
				<TextField
					size="sm"
					name="email"
					control={control}
					inputMode="email"
					autoComplete="username"
					leftSection={<IconAt size={16} stroke={1.5} />}
					placeholder={t('auth.resetPassword.form.email.placeholder')}
				/>

				<Button
					type="submit"
					variant="light"
					loading={isSubmitting}
				>
					{t('auth.resetPassword.form.submit')}
				</Button>
			</Stack>
		</form>
	);
}
