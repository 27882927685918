import { Flex, FlexProps, Text, UnstyledButton } from '@mantine/core';
import { i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import paths from '../../routes/paths';

function getColor(i18n: i18n, lang: string) {
	return i18n.language === lang ? 'gray.0' : 'gray.6';
}

interface FooterProps
	extends Omit<FlexProps, 'children'> {}

export default function Footer(props: FooterProps) {
	const { t, i18n } = useTranslation();

	return (
		<Flex
			px="sm"
			w="100%"
			align="center"
			justify="space-between"
			{...props}
		>
			<Flex gap="xs">
				<Text
					fz="xs"
					fw="400"
					c="gray.6"
					component="a"
					href={paths.termsOfService}
				>
					{t('legal.termsOfService.anchor')}
				</Text>

				<Text
					fz="xs"
					fw="400"
					c="gray.6"
					component="a"
					href={paths.privacyPolicy}
				>
					{t('legal.privacyPolicy.anchor')}
				</Text>
			</Flex>

			<Flex gap="xs" justify="center" align="center">
				<UnstyledButton
					onClick={() => i18n.changeLanguage('tr')}
				>
					<Text
						fz="xs"
						fw="400"
						c={getColor(i18n, 'tr')}
					>
						TR
					</Text>
				</UnstyledButton>
				<UnstyledButton
					onClick={() => i18n.changeLanguage('en')}
				>
					<Text
						fz="xs"
						fw="400"
						c={getColor(i18n, 'en')}
					>
						EN
					</Text>
				</UnstyledButton>
			</Flex>
		</Flex>
	);
}
