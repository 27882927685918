import { Box, Button, FileInput, FileInputProps, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { useRef } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface ImageFieldProps<T extends FieldValues> extends
	Omit<FileInputProps, 'name' | 'label'> {
	name: Path<T>;
	label: string;
	control: Control<T>;
}

export default function ImageField<T extends FieldValues>({
	name,
	label,
	control,
	onChange,
	...inputProps
}: ImageFieldProps<T>) {
	const ref = useRef<HTMLButtonElement>(null);

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => {
				const exists = !!(field.value as File);

				return (
					<Box>
						<Button.Group>
							<Button
								flex="1"
								size="xs"
								variant="default"
								onClick={() => ref.current?.click()}
							>
								{label}
							</Button>

							{exists && (
								<Button
									px="8"
									size="xs"
									variant="default"
									onClick={() => {
										field.onChange(null);
										onChange?.(null);
									}}
								>
									<IconTrash size="14" />
								</Button>
							)}
						</Button.Group>

						{error && (
							<Text fz="xs" c="red.3" mt="xs">
								{error?.message}
							</Text>
						)}

						<FileInput
							{...field}
							{...inputProps}
							ref={ref}
							style={{ display: 'none' }}
							onChange={(file) => {
								field.onChange(file);
								onChange?.(file);
							}}
						/>
					</Box>
				);
			}}
		/>
	);
};
