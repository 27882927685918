import { createContext } from 'react';
import { PageInfo } from '../../types';
import { QuestionFieldsFragment } from './__generated__/QuestionsQuery';

export interface QuestionsContextType {
	error: boolean;
	loading: boolean;
	pageInfo: PageInfo;
	questions: QuestionFieldsFragment[];
}

export default createContext<QuestionsContextType | null>(null);
