import { z } from 'zod';
import { i18n } from '../../../locales';

export const resetPasswordCompleteFormSchema = z.object({
	password: z.string()
		.min(8, i18n.t('auth.resetPasswordComplete.form.password.minLength'))
		.max(20, i18n.t('auth.resetPasswordComplete.form.password.maxLength'))
		.regex(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
			i18n.t('auth.resetPasswordComplete.form.password.invalid'),
		),
});

export type ResetPasswordCompleteFormInput = z.infer<typeof resetPasswordCompleteFormSchema>;

export const resetPasswordCompleteFormInitialValues: ResetPasswordCompleteFormInput
	= { password: '' };
