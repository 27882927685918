import { Button, Flex, Popover, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconClock } from '@tabler/icons-react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReplyDeletePeriod } from '../../../../types';

interface QuestionReplyDeletePeriodFieldProps<T extends FieldValues> {
	name: Path<T>;
	control: Control<T>;
	disabled?: boolean;
}

export default function QuestionReplyDeletePeriodField<T extends FieldValues>({
	name,
	control,
	disabled,
}: QuestionReplyDeletePeriodFieldProps<T>) {
	const { t } = useTranslation();
	const [, setDefaultReplyDeletePeriod] = useLocalStorage({
		key: 'app:reply-delete-period',
		defaultValue: ReplyDeletePeriod.Never,
	});

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<Popover
					withArrow
					offset={6}
					shadow="md"
					width={260}
					arrowOffset={22}
					position="bottom-end"
				>
					<Popover.Target>
						<Button
							px="8"
							size="xs"
							color="teal.8"
							disabled={disabled}
						>
							<IconClock size={14} />
						</Button>
					</Popover.Target>

					<Popover.Dropdown>
						<Text fz="xs" mb="xs">
							{t('questions.form.deletionInfo')}
						</Text>

						<Flex wrap="wrap" gap="xs">
							<Button
								size="xs"
								onClick={() => {
									field.onChange(ReplyDeletePeriod.Never);
									setDefaultReplyDeletePeriod(ReplyDeletePeriod.Never);
								}}
								color={field.value === ReplyDeletePeriod.Never ? undefined : 'teal.7'}
								variant={field.value === ReplyDeletePeriod.Never ? 'filled' : 'light'}
							>
								{t('questions.form.deletePeriods.never')}
							</Button>

							<Button
								size="xs"
								onClick={() => {
									field.onChange(ReplyDeletePeriod.HalfHour);
									setDefaultReplyDeletePeriod(ReplyDeletePeriod.HalfHour);
								}}
								color={field.value === ReplyDeletePeriod.HalfHour ? undefined : 'teal.7'}
								variant={field.value === ReplyDeletePeriod.HalfHour ? 'filled' : 'light'}
							>
								{t('questions.form.deletePeriods.30m')}
							</Button>

							<Button
								size="xs"
								onClick={() => {
									field.onChange(ReplyDeletePeriod.OneHour);
									setDefaultReplyDeletePeriod(ReplyDeletePeriod.OneHour);
								}}
								color={field.value === ReplyDeletePeriod.OneHour ? undefined : 'teal.7'}
								variant={field.value === ReplyDeletePeriod.OneHour ? 'filled' : 'light'}
							>
								{t('questions.form.deletePeriods.1h')}
							</Button>

							<Button
								size="xs"
								onClick={() => {
									field.onChange(ReplyDeletePeriod.TwoHours);
									setDefaultReplyDeletePeriod(ReplyDeletePeriod.TwoHours);
								}}
								color={field.value === ReplyDeletePeriod.TwoHours ? undefined : 'teal.7'}
								variant={field.value === ReplyDeletePeriod.TwoHours ? 'filled' : 'light'}
							>
								{t('questions.form.deletePeriods.2h')}
							</Button>

							<Button
								size="xs"
								onClick={() => {
									field.onChange(ReplyDeletePeriod.FourHours);
									setDefaultReplyDeletePeriod(ReplyDeletePeriod.FourHours);
								}}
								color={field.value === ReplyDeletePeriod.FourHours ? undefined : 'teal.7'}
								variant={field.value === ReplyDeletePeriod.FourHours ? 'filled' : 'light'}
							>
								{t('questions.form.deletePeriods.4h')}
							</Button>

							<Button
								size="xs"
								onClick={() => {
									field.onChange(ReplyDeletePeriod.EightHours);
									setDefaultReplyDeletePeriod(ReplyDeletePeriod.EightHours);
								}}
								color={field.value === ReplyDeletePeriod.EightHours ? undefined : 'teal.7'}
								variant={field.value === ReplyDeletePeriod.EightHours ? 'filled' : 'light'}
							>
								{t('questions.form.deletePeriods.8h')}
							</Button>

							<Button
								size="xs"
								onClick={() => {
									field.onChange(ReplyDeletePeriod.OneDay);
									setDefaultReplyDeletePeriod(ReplyDeletePeriod.OneDay);
								}}
								color={field.value === ReplyDeletePeriod.OneDay ? undefined : 'teal.7'}
								variant={field.value === ReplyDeletePeriod.OneDay ? 'filled' : 'light'}
							>
								{t('questions.form.deletePeriods.1d')}
							</Button>
						</Flex>
					</Popover.Dropdown>
				</Popover>
			)}
		/>
	);
}
