import { ComponentType, useEffect, useRef } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAccount } from '../../../contexts/account';
import paths from '../../paths';

export function withAccount(Component: ComponentType) {
	return () => {
		const { account } = useAccount<'optional'>();

		const navigate = useNavigate();
		const usernameRef = useRef(account?.username);

		useEffect(() => {
			if (usernameRef.current !== account?.username) {
				usernameRef.current = account?.username;
				navigate(generatePath(paths.settings, { username: account?.username ?? '' }));
			}
		}, [account?.username, navigate]);

		return <Component key={JSON.stringify(account)} />;
	};
}
