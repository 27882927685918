import { useQuery } from '@apollo/client';
import { Button, Stack } from '@mantine/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Link from '../../components/Link';
import ReplyComponent from '../../components/Reply';
import { useAccount } from '../../contexts/account';
import { useProfile } from '../../contexts/profile';
import ReplyEmpty from './ReplyEmpty';
import ReplyFailed from './ReplyFailed';
import { ReplyQuery } from './ReplyQuery';
import ReplySkeleton from './ReplySkeleton';

export default function Reply() {
	const { t } = useTranslation();
	const { profile } = useProfile();
	const { account } = useAccount<'optional'>();

	const { replyId } = useParams<'replyId'>();
	const { data, loading, error } = useQuery(ReplyQuery, {
		variables: { replyId: replyId || '' },
	});

	if (loading && !error) {
		return (
			<Fragment>
				<Helmet>
					<title>
						{t('profile.pageTitle', { username: profile?.username ?? '' })}
					</title>
				</Helmet>

				<ReplySkeleton />
			</Fragment>
		);
	}

	if (!loading && error) {
		return (
			<Fragment>
				<Helmet>
					<title>
						{t('profile.pageTitle', { username: profile?.username ?? '' })}
					</title>
				</Helmet>

				<ReplyFailed />
			</Fragment>
		);
	}

	if (!data?.reply) {
		return (
			<Fragment>
				<Helmet>
					<title>
						{t('profile.pageTitle', { username: profile?.username ?? '' })}
					</title>
				</Helmet>

				<ReplyEmpty />
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Helmet>
				<title>
					{t('profile.pageTitle', { username: profile?.username ?? '' })}
				</title>
			</Helmet>

			<Stack gap="lg" justify="flex-start" align="center">
				<ReplyComponent {...data?.reply} />

				{!account && (
					<Button
						size="xs"
						variant="light"
						component={Link}
						to={`/@/${profile?.username}`}
					>
						{t('replies.reply.loadMore')}
					</Button>
				)}
			</Stack>
		</Fragment>
	);
}
