import { Fragment, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Routes as BaseRoutes, Location, matchRoutes, Route, useLocation } from 'react-router-dom';
import AppLayout from '../layouts/AppLayout';
import AuthLayout from '../layouts/AuthLayout';
import ModalLayout from '../layouts/ModalLayout';
import ProfileLayout from '../layouts/ProfileLayout';
import SimpleLayout from '../layouts/SimpleLayout';
import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import NotFound from './NotFound';
import paths from './paths';
import PrivacyPolicy from './PrivacyPolicy';
import Questions from './Questions';
import Register from './Register';
import RegisterComplete from './RegisterComplete';
import Replies from './Replies';
import Reply from './Reply';
import RequireAnonymous from './RequireAnonymous';
import RequireAuth from './RequireAuth';
import ResetPassword from './ResetPassword';
import ResetPasswordComplete from './ResetPasswordComplete';
import Settings from './Settings';
import TermsOfService from './TermsOfService';

const DEFAULT_TITLE = 'Whispi.io';
const TITLE_TEMPLATE = '%s | Whispi.io';

export default function Routes() {
	const location = useLocation();
	const previousRef = useRef<Location>();

	const isModalRoute = !!matchRoutes([{ path: paths.reply }], location)?.length;
	const isScrollRequested = !!location.state?.scroll;

	useEffect(() => {
		previousRef.current = location;
	}, [location]);

	useEffect(() => {
		if (isScrollRequested) {
			window.scrollTo(0, 0);
		}
	}, [isScrollRequested]);

	return (
		<Fragment>
			<Helmet
				defaultTitle={DEFAULT_TITLE}
				titleTemplate={TITLE_TEMPLATE}
			/>

			<BaseRoutes location={isModalRoute && previousRef.current ? previousRef.current : location}>
				<Route element={<SimpleLayout />}>
					<Route path={paths.home} element={<Home />} />

					<Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
					<Route path={paths.termsOfService} element={<TermsOfService />} />

					<Route path="*" element={<NotFound />} />
				</Route>

				<Route element={<AppLayout />}>
					<Route element={<ProfileLayout />}>
						<Route path={paths.reply} element={<Reply />} />
						<Route path={paths.profile} element={<Replies />} />

						<Route element={<RequireAuth />}>
							<Route path={paths.settings} element={<Settings />} />
							<Route path={paths.questions} element={<Questions />} />
						</Route>
					</Route>
				</Route>

				<Route element={<RequireAnonymous />}>
					<Route element={<AuthLayout />}>
						<Route path={paths.login} element={<Login />} />
						<Route path={paths.register} element={<Register />} />
						<Route path={paths.resetPassword} element={<ResetPassword />} />
						<Route path={paths.registerComplete} element={<RegisterComplete />} />
						<Route path={paths.resetPasswordComplete} element={<ResetPasswordComplete />} />
					</Route>
				</Route>

				<Route element={<RequireAuth />}>
					<Route path={paths.logout} element={<Logout />} />
				</Route>
			</BaseRoutes>

			{isModalRoute && previousRef.current && (
				<BaseRoutes location={location}>
					<Route element={<ModalLayout />}>
						<Route element={<AppLayout />}>
							<Route element={<ProfileLayout />}>
								<Route path={paths.reply} element={<Reply />} />
							</Route>
						</Route>
					</Route>
				</BaseRoutes>
			)}
		</Fragment>
	);
}
