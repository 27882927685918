import { gql, TypedDocumentNode } from '@apollo/client';
import {
	ResetPasswordCompleteMutation,
	ResetPasswordCompleteMutationVariables,
} from './__generated__/ResetPasswordCompleteMutation';

export const ResetPasswordComplete: TypedDocumentNode<ResetPasswordCompleteMutation, ResetPasswordCompleteMutationVariables> = gql`
    mutation ResetPasswordComplete($input: ResetPasswordCompleteInput!) {
        resetPasswordComplete(input: $input) {
            success
            errors {
                code
            }
        }
    }
`;
