import { Paper } from '@mantine/core';

import { generatePath, Link } from 'react-router-dom';
import { useProfile } from '../../../contexts/profile';
import { ReplyFieldsFragment } from '../../../contexts/replies/__generated__/RepliesQuery';
import paths from '../../../routes/paths';
import { ReplyContent } from '../../Reply';

interface QuestionParentProps
	extends Pick<ReplyFieldsFragment, 'id' | 'parent' | 'content' | 'createdAt'> {}

export default function QuestionParent({
	id,
	parent,
	content,
}: QuestionParentProps) {
	const { profile } = useProfile();

	return (
		<Paper
			p="xs"
			w="100%"
			withBorder
			bg="dark.9"
			component={Link}
			to={generatePath(paths.reply, { username: profile?.username ?? '', replyId: id })}
		>
			<ReplyContent
				parent={parent}
				content={content}
			/>
		</Paper>
	);
}
