import Reply from '../../../components/Reply';
import { useReplies } from '../../../contexts/replies';

export default function RepliesList() {
	const { replies } = useReplies();

	return replies.map(reply => (
		<Reply
			key={reply.id}
			{...reply}
		/>
	));
}
