import { Box, getGradient, Paper, useMantineTheme } from '@mantine/core';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useProfile } from '../../../contexts/profile';
import { ReplyFieldsFragment } from '../../../contexts/replies/__generated__/RepliesQuery';
import paths from '../../../routes/paths';
import Link from '../../Link';
import ReplyContent from '../ReplyContent';

interface ReplyParentProps
	extends Pick<ReplyFieldsFragment, 'id' | 'parent' | 'content'> {}

const MAX_CONTENT_HEIGHT = 142;

export default function ReplyParent({
	id,
	parent,
	content,
}: ReplyParentProps) {
	const { profile } = useProfile();

	const ref = useRef<HTMLParagraphElement>(null);
	const theme = useMantineTheme();

	const [height, setHeight] = useState<number | null>(null);
	const isOverflown = height && height > MAX_CONTENT_HEIGHT;

	const gradient = useMemo(() => getGradient({
		to: 'dark',
		deg: 180,
		from: 'transparent',
	}, theme), [theme]);

	useLayoutEffect(() => {
		if (ref.current) {
			setHeight(ref.current.clientHeight);
		}
	}, []);

	return (
		<Paper
			p="xs"
			w="100%"
			bg="dark.9"
			withBorder
			component={Link}
			to={generatePath(paths.reply, {
				replyId: id,
				username: profile?.username ?? '',
			})}
		>
			<Paper
				p="0"
				ref={ref}
				pos="relative"
				h={isOverflown ? MAX_CONTENT_HEIGHT : 'auto'}
				style={{ overflow: isOverflown ? 'hidden' : 'visible' }}
			>
				<Box
					ref={ref}
					h={isOverflown ? MAX_CONTENT_HEIGHT : 'auto'}
					style={{ overflow: isOverflown ? 'hidden' : 'visible' }}
				>
					<ReplyContent
						parent={parent}
						content={content}
					/>
				</Box>

				{isOverflown && (
					<Paper
						h="60"
						w="100%"
						left="0"
						bottom="0"
						radius="0"
						bg={gradient}
						pos="absolute"
					/>
				)}
			</Paper>
		</Paper>
	);
}
