import {
	Box,
	Flex,
	getGradient,
	Paper,
	Text,
	TextProps,
	Tooltip,
	UnstyledButton,
	useMantineTheme,
} from '@mantine/core';
import { IconChevronRight, IconChevronsDown, IconChevronsUp } from '@tabler/icons-react';
import { Fragment, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ReplyContentTextProps
	extends TextProps {
	reply?: boolean;
	children: string;
}

const MAX_HEIGHT = 200;

export default function ReplyContentText({
	reply = false,
	children,
	...textProps
}: ReplyContentTextProps) {
	const { t } = useTranslation();

	const ref = useRef<HTMLParagraphElement>(null);
	const theme = useMantineTheme();

	const [opened, setOpened] = useState(false);
	const [height, setHeight] = useState<number | null>(null);
	const isOverflown = height && height > MAX_HEIGHT;

	const gradient = useMemo(() => getGradient({
		to: 'dark.7',
		deg: 180,
		from: 'transparent',
	}, theme), [theme]);

	useLayoutEffect(() => {
		if (ref.current) {
			setHeight(ref.current.clientHeight);
		}
	}, []);

	return (
		<Paper
			p="0"
			w="100%"
			radius="0"
			pos="relative"
			bg="transparent"
		>
			<Text
				ref={ref}
				component="p"
				{...textProps}
				h={isOverflown && !opened ? MAX_HEIGHT : 'auto'}
				style={{ overflow: isOverflown && !opened ? 'hidden' : 'visible' }}
			>
				{children.split('\n').map((line, index) => (
				// eslint-disable-next-line react/no-array-index-key
					<Fragment key={index}>
						{reply && index === 0 && (
							<Box
								h="16"
								w="16"
								pos="relative"
								component="span"
								display="inline-flex"
							>
								<IconChevronRight
									size="14"
									stroke="1.5"
									style={{
										top: '4px',
										position: 'absolute',
									}}
								/>
							</Box>
						)}
						{line}
						<br />
					</Fragment>
				))}
			</Text>

			{isOverflown && !opened && (
				<Paper
					h="80"
					w="100%"
					left="0"
					bottom="0"
					radius="0"
					bg={gradient}
					pos="absolute"
				>
					<Flex
						w="100%"
						h="100%"
						pos="relative"
					>
						<Tooltip
							fz="xs"
							color="dark"
							label={t('replies.card.showMore')}
						>
							<UnstyledButton
								right="0"
								bottom="0"
								pos="absolute"
								onClick={() => setOpened(true)}
							>
								<Box
									h="14"
									w="14"
									c="gray.6"
									display="inline-flex"
								>
									<IconChevronsDown size="14" />
								</Box>
							</UnstyledButton>
						</Tooltip>
					</Flex>
				</Paper>
			)}

			{isOverflown && opened && (
				<Flex
					w="100%"
					h="100%"
					align="flex-end"
					justify="flex-end"
				>
					<Tooltip
						fz="xs"
						color="dark"
						label={t('replies.card.showLess')}
					>
						<UnstyledButton onClick={() => setOpened(false)}>
							<Box
								h="14"
								w="14"
								c="gray.6"
								display="inline-flex"
							>
								<IconChevronsUp size="14" />
							</Box>
						</UnstyledButton>
					</Tooltip>
				</Flex>
			)}
		</Paper>
	);
}
