import { Flex, getGradient, Paper, Text, Tooltip, UnstyledButton, useMantineTheme } from '@mantine/core';
import { IconChevronsDown, IconChevronsUp } from '@tabler/icons-react';
import { Fragment, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionFieldsFragment } from '../../../contexts/questions/__generated__/QuestionsQuery';

interface QuestionContentProps
	extends Pick<QuestionFieldsFragment, 'content'> {}

const MAX_HEIGHT = 200;

export default function QuestionContent({ content }: QuestionContentProps) {
	const { t } = useTranslation();

	const ref = useRef<HTMLParagraphElement>(null);
	const theme = useMantineTheme();

	const [opened, setOpened] = useState(false);
	const [height, setHeight] = useState<number | null>(null);
	const isOverflown = height && height > MAX_HEIGHT;

	const gradient = useMemo(() => getGradient({
		to: 'dark',
		deg: 180,
		from: 'transparent',
	}, theme), [theme]);

	useLayoutEffect(() => {
		if (ref.current) {
			setHeight(ref.current.clientHeight);
		}
	}, []);

	return (
		<Paper
			p="sm"
			w="100%"
			radius="md"
			pos="relative"
		>
			<Text
				fz="sm"
				fw="300"
				ref={ref}
				component="p"
				h={isOverflown && !opened ? MAX_HEIGHT : 'auto'}
				style={{ overflow: isOverflown && !opened ? 'hidden' : 'visible' }}
			>
				{content.split('\n').map((line, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<Fragment key={index}>
						{line}
						<br />
					</Fragment>
				))}
			</Text>

			{isOverflown && !opened && (
				<Paper
					p="sm"
					h="60"
					w="100%"
					left="0"
					bottom="0"
					radius="md"
					bg={gradient}
					pos="absolute"
				>
					<Tooltip
						fz="xs"
						color="dark"
						label={t('questions.card.showMore')}
					>
						<UnstyledButton
							right="0"
							bottom="0"
							pos="absolute"
							onClick={() => setOpened(true)}
						>
							<IconChevronsDown size="14" />
						</UnstyledButton>
					</Tooltip>
				</Paper>
			)}

			{isOverflown && opened && (
				<Flex
					w="100%"
					h="100%"
					align="flex-end"
					justify="flex-end"
				>
					<Tooltip
						fz="xs"
						color="dark"
						label={t('questions.card.showLess')}
					>
						<UnstyledButton onClick={() => setOpened(false)}>
							<IconChevronsUp size="14" />
						</UnstyledButton>
					</Tooltip>
				</Flex>
			)}
		</Paper>
	);
}
