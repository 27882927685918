import { useMutation } from '@apollo/client';
import { Alert, Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconLockCancel } from '@tabler/icons-react';
import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import analytics from '../../api/analytics';
import Link from '../../components/Link';
import useToken from '../../contexts/token/useToken';
import { AuthErrorCode } from '../../types';
import paths from '../paths';
import { REDIRECT_SEARCH_PARAM } from '../RequireAuth';
import LoginForm, { LoginFormInput } from './LoginForm';
import { Login as LoginMutation } from './LoginMutation';

export default function Login() {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const { setToken } = useToken();

	const [login] = useMutation(LoginMutation);
	const [error, setError] = useState<string | null>(null);

	const handleSubmit = async ({ password, identifier, remember }: LoginFormInput) => {
		try {
			const { data } = await login({
				variables: {
					input: {
						password,
						identifier,
					},
				},
			});

			const {
				errors,
				account,
				accessToken,
			} = data?.login || {};

			if (!accessToken || !account || !!errors?.length) {
				if (errors?.some(e => e.code === AuthErrorCode.InvalidCredentials)) {
					setError(t('auth.login.error.invalidCredentials'));
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			const params = new URLSearchParams(location.search);
			const redirect = params.get(REDIRECT_SEARCH_PARAM)
				|| generatePath(paths.profile, { username: account.username });

			analytics.identify(account);

			setToken(accessToken, remember);
			navigate(redirect, { replace: true });
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	return (
		<Fragment>
			<Helmet>
				<title>{t('auth.login.pageTitle')}</title>
			</Helmet>

			<Stack gap="0.25rem">
				<Paper w={324} bg="dark.9" p="lg">
					<Stack gap="sm">
						<Stack px="3rem" gap="0" align="center">
							<Text fz="2rem" ff="var(--ff-title)">
								{t('auth.login.title')}
							</Text>
							<Text fz="sm" fw="200" ta="center">
								{t('auth.login.subtitle')}
							</Text>
						</Stack>

						<Divider />

						{!!error && (
							<Alert color="red" variant="light" icon={<IconLockCancel />}>
								<Text fz="sm" c="red" fw="normal">
									{error}
								</Text>
							</Alert>
						)}

						<LoginForm onSubmit={handleSubmit} />
					</Stack>
				</Paper>
				<Flex justify="center" pl="xs" gap=".25rem">
					<Trans
						i18nKey="auth.login.noAccount"
						components={[
							<Text key="0" fz="xs" fw="200" />,
							<Text
								key="1"
								fz="xs"
								fw="500"
								c="teal.8"
								component={Link}
								to={paths.register}
							/>,
						]}
					/>
				</Flex>
			</Stack>
		</Fragment>
	);
}
