import { Flex, Paper, Skeleton, Stack } from '@mantine/core';

export default function RepliesSkeleton() {
	return (
		Array.from({ length: 12 }).map((_, index) => (
			<Paper
				p="md"
				w="100%"
				bg="dark.9"
				// eslint-disable-next-line react/no-array-index-key
				key={index}
			>
				<Stack gap="6">
					<Flex justify="space-between">
						<Flex gap="6" justify="center" align="center">
							<Skeleton h="38" w="38" radius="md" />

							<Stack gap="4">
								<Skeleton h="18" w="128" />
								<Skeleton h="14" w="100" />
							</Stack>
						</Flex>

						<Flex gap="4">
							<Skeleton h="15" w="100" />
							<Skeleton h="15" w="15" />
						</Flex>
					</Flex>

					<Stack gap="xs">
						<Skeleton h="100" w="100%" />
					</Stack>
				</Stack>
			</Paper>
		))
	);
}
