import { gql, TypedDocumentNode } from '@apollo/client';
import { DeleteReplyMutation, DeleteReplyMutationVariables } from './__generated__/ReplyMutation';

export const DeleteReply: TypedDocumentNode<
	DeleteReplyMutation,
	DeleteReplyMutationVariables
> = gql`
	mutation DeleteReply($id: ID!) {
		deleteReply(id: $id) {
			success
			errors {
				code
			}
		}
	}
`;
