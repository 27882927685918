import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Flex, Stack } from '@mantine/core';
import { IconAt } from '@tabler/icons-react';

import { useForm, UseFormReturn } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import TextField from '../../../fields/TextField';
import {
	registerFormInitialValues,
	RegisterFormInput,
	registerFormSchema,
} from './RegisterForm.utils';

interface RegisterFormProps {
	onSubmit: (
		values: RegisterFormInput,
		form: UseFormReturn<RegisterFormInput>
	) => Promise<void>;
}

export default function RegisterForm({ onSubmit }: RegisterFormProps) {
	const { t } = useTranslation();
	const form = useForm<RegisterFormInput>({
		resolver: zodResolver(registerFormSchema),
		defaultValues: registerFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(values => onSubmit(values, form));

	return (
		<form noValidate onSubmit={onSubmitHandler}>
			<Stack gap="sm">
				<TextField
					size="sm"
					name="email"
					control={control}
					inputMode="email"
					autoComplete="username"
					leftSection={<IconAt size={16} stroke={1.5} />}
					placeholder={t('auth.register.form.email.placeholder')}
				/>

				<Flex justify="flex-end">
					<Button
						type="submit"
						variant="light"
						loading={isSubmitting}
					>
						{t('auth.register.form.submit')}
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
