import { Avatar, Button, Flex, Menu, Text, UnstyledButton } from '@mantine/core';
import {
	IconLogout,
	IconSettings,
	IconUser,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import Link from '../../../components/Link';
import { useAccount } from '../../../contexts/account';
import generateInitials from '../../../helpers/generateInitials';
import paths from '../../../routes/paths';

export default function Header() {
	const { t } = useTranslation();
	const { account } = useAccount<'optional'>();

	return (
		<Flex
			w="100%"
			align="center"
			component="header"
			justify="space-between"
		>
			<Text
				lh="1"
				td="none"
				c="gray.3"
				fz="1.2rem"
				to={paths.home}
				component={Link}
				ff="var(--ff-title)"
			>
				whispi.io
			</Text>

			{!account
				? (
					<Flex gap="xs">
						<Button
							size="xs"
							variant="subtle"
							to={paths.login}
							component={Link}
						>
							{t('layout.header.login')}
						</Button>
						<Button
							size="xs"
							variant="light"
							component={Link}
							to={paths.register}
						>
							{t('layout.header.register')}
						</Button>
					</Flex>
				)
				: (
					<Menu shadow="md" position="bottom-end" width={180}>
						<Menu.Target>
							<UnstyledButton>
								<Avatar
									radius="md"
									size="1.8rem"
									src={account.profileImage}
								>
									{generateInitials(account.fullName)}
								</Avatar>
							</UnstyledButton>
						</Menu.Target>

						<Menu.Dropdown>
							<Link
								style={{ textDecoration: 'none' }}
								to={generatePath(paths.profile, { username: account.username })}
							>
								<Menu.Item leftSection={<IconUser size="14" />}>
									{t('layout.header.menu.account')}
								</Menu.Item>
							</Link>

							<Link
								style={{ textDecoration: 'none' }}
								to={generatePath(paths.settings, { username: account.username })}
							>
								<Menu.Item leftSection={<IconSettings size="14" />}>
									{t('layout.header.menu.settings')}
								</Menu.Item>
							</Link>

							<Menu.Divider />

							<Link to={paths.logout} style={{ textDecoration: 'none' }}>
								<Menu.Item color="red" leftSection={<IconLogout size="14" />}>
									{t('layout.header.menu.logout')}
								</Menu.Item>
							</Link>
						</Menu.Dropdown>
					</Menu>
				)}
		</Flex>
	);
}
