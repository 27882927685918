import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SettingsAccount from './SettingsAccount';
import SettingsInformation from './SettingsInformation';
import SettingsPassword from './SettingsPassword';

export default function Settings() {
	const { t } = useTranslation();

	return (
		<Fragment>
			<Helmet>
				<title>{t('settings.pageTitle')}</title>
			</Helmet>

			<SettingsInformation />
			<SettingsPassword />
			<SettingsAccount />
		</Fragment>
	);
}
