import { gql, TypedDocumentNode } from '@apollo/client';
import { AccountFields } from '../../contexts/account/AccountQuery';
import { LoginMutation, LoginMutationVariables } from './__generated__/LoginMutation';

export const Login: TypedDocumentNode<LoginMutation, LoginMutationVariables> = gql`
		mutation Login($input: LoginInput!) {
				login(input: $input) {
						account {
								...AccountFields
						}
						accessToken
						errors {
								code
						}
				}
		}

		${AccountFields}
`;
