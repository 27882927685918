import { Button, Flex, Modal, Paper } from '@mantine/core';
import {
	IconBrandFacebook,
	IconBrandTelegram,
	IconBrandWhatsapp,
	IconBrandX,
	IconCopy,
} from '@tabler/icons-react';
import { SharePayload } from '../ShareContext';
import { copyToClipboard } from './ShareModal.utils';

interface ShareModalProps {
	opened: boolean;
	payload: SharePayload;
	onRequestClose: () => void;
}

export default function ShareModal({
	opened,
	onRequestClose,
	payload: {
		url,
		text,
		title,
	},
}: ShareModalProps) {
	const xURL = `https://x.com/intent/tweet?text=${text}%0A@whispidotio%0A${url}`;
	const telegramURL = `https://t.me/share/url?url=${url}&text=${text}`;
	const whatsappURL = `https://api.whatsapp.com/send?text=${text}%0A${url}`;
	const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

	return (
		<Modal
			centered
			title={title}
			opened={opened}
			onClose={onRequestClose}
		>
			<Flex p="md" pt="0" w="100%">
				<Paper p="md" bg="dark.8" w="100%" withBorder>
					<Flex gap="xs" justify="center">
						<Button
							p="0"
							size="sm"
							w="2.5rem"
							h="2.5rem"
							bg="black"
							href={xURL}
							component="a"
							target="_blank"
							rel="noopener noreferrer"
						>
							<IconBrandX size={24} stroke={1.5} />
						</Button>

						<Button
							p="0"
							size="sm"
							w="2.5rem"
							h="2.5rem"
							bg="#25D366"
							component="a"
							target="_blank"
							href={whatsappURL}
							rel="noopener noreferrer"
						>
							<IconBrandWhatsapp size={24} stroke={1.5} />
						</Button>

						<Button
							p="0"
							size="sm"
							w="2.5rem"
							h="2.5rem"
							bg="#4267B2"
							component="a"
							target="_blank"
							href={facebookURL}
							rel="noopener noreferrer"
						>
							<IconBrandFacebook size={24} stroke={1.5} />
						</Button>

						<Button
							p="0"
							size="sm"
							w="2.5rem"
							h="2.5rem"
							bg="#0088cc"
							component="a"
							target="_blank"
							href={telegramURL}
							rel="noopener noreferrer"
						>
							<IconBrandTelegram size={24} stroke={1.5} />
						</Button>

						<Button
							p="0"
							bg="dark"
							size="sm"
							w="2.5rem"
							h="2.5rem"
							onClick={() => copyToClipboard(url)}
						>
							<IconCopy
								size={22}
								stroke={1.5}
								transform="rotate(90)"
							/>
						</Button>
					</Flex>
				</Paper>
			</Flex>
		</Modal>
	);
}
