import { z } from 'zod';
import { i18n } from '../../../locales';

export const resetPasswordFormSchema = z.object({
	email: z.string()
		.email(i18n.t('auth.resetPassword.form.email.invalid'))
		.min(1, i18n.t('auth.resetPassword.form.email.required')),
});

export type ResetPasswordFormInput = z.infer<typeof resetPasswordFormSchema>;

export const resetPasswordFormInitialValues: ResetPasswordFormInput = {
	email: '',
};
