import { useQuery } from '@apollo/client';

import { useLocalStorage } from '@mantine/hooks';
import { PropsWithChildren, useMemo } from 'react';
import GuestContext from './GuestContext';
import { GuestReplyIds } from './GuestReplyIdsQuery';

export default function GuestProvider({ children }: PropsWithChildren<any>) {
	const [guestId, setGuestId] = useLocalStorage<string | null>({
		key: 'app:guest-id',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const { data, refetch } = useQuery(GuestReplyIds, {
		skip: !guestId,
		variables: { guestId: guestId! },
		errorPolicy: 'ignore',
		fetchPolicy: 'cache-and-network',
	});

	const contextValue = useMemo(() => ({
		guestId,
		replyIds: data?.guestReplyIds || [],
		updateGuestId: async (id: string) => {
			setGuestId(id);
			await refetch();
		},
	}), [guestId, data?.guestReplyIds, setGuestId, refetch]);

	return (
		<GuestContext.Provider value={contextValue}>
			{children}
		</GuestContext.Provider>
	);
}
