import { Button, Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import Link from '../../components/Link';
import { useAccount } from '../../contexts/account';
import paths from '../paths';

export default function NotFound() {
	const { t } = useTranslation();
	const { account } = useAccount<'optional'>();

	return (
		<Flex justify="center" align="center">
			<Paper w={324} bg="dark.9" p="lg">
				<Stack gap="md">
					<Stack gap="md" align="center">
						<Text ta="center" lh="1" fz="2rem" ff="var(--ff-title)">
							{t('layout.notFound.title')}
						</Text>
						<Text fz="sm" fw="200" ta="center" px="3rem">
							{t('layout.notFound.subtitle')}
						</Text>
					</Stack>

					<Divider />

					<Flex
						w="100%"
						gap="xs"
						align="center"
						justify="center"
					>
						{account
							? (
								<Button
									size="xs"
									variant="light"
									component={Link}
									to={generatePath(paths.profile, { username: account.username })}
								>
									{t('layout.notFound.return')}
								</Button>
							)
							: (
								<>
									<Button
										size="xs"
										variant="subtle"
										to={paths.login}
										component={Link}
									>
										{t('layout.notFound.login')}
									</Button>
									<Button
										size="xs"
										variant="light"
										component={Link}
										to={paths.register}
									>
										{t('layout.notFound.register')}
									</Button>
								</>
							)}
					</Flex>
				</Stack>
			</Paper>
		</Flex>
	);
}
