import { useQuery } from '@apollo/client';
import { LoadingOverlay } from '@mantine/core';

import { PropsWithChildren, useMemo } from 'react';
import analytics from '../../api/analytics';
import { allQuestionsDeletedEvent, questionDeletedEvent, replyCreatedEvent, useEvent } from '../../events';
import { useToken } from '../token';
import AccountContext from './AccountContext';
import { AccountQuery, AccountQuestionCountQuery } from './AccountQuery';

const POLL_INTERVAL = 10_000;

export default function AccountProvider({ children }: PropsWithChildren<any>) {
	const { token } = useToken();

	const { data, loading } = useQuery(AccountQuery, {
		skip: !token,
		errorPolicy: 'ignore',
		fetchPolicy: 'cache-and-network',
		onCompleted: async (data) => {
			if (!data.me) {
				return;
			}

			analytics.identify(data.me);
		},
	});

	const {
		data: questionCountData,
		refetch: refetchQuestionCount,
	} = useQuery(AccountQuestionCountQuery, {
		skip: !data?.me,
		pollInterval: POLL_INTERVAL,
	});

	useEvent(replyCreatedEvent, async () => {
		await refetchQuestionCount();
	});

	useEvent(questionDeletedEvent, async () => {
		await refetchQuestionCount();
	});

	useEvent(allQuestionsDeletedEvent, async () => {
		await refetchQuestionCount();
	});

	const contextValue = useMemo(
		() => ({
			account: !token ? null : data?.me || null,
			questionCount: questionCountData?.questions.pageInfo.total || 0,
		}),
		[
			token,
			data?.me,
			questionCountData?.questions.pageInfo.total,
		],
	);

	if (loading) {
		return <LoadingOverlay />;
	}

	return (
		<AccountContext.Provider value={contextValue}>
			{children}
		</AccountContext.Provider>
	);
}
