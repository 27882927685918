import { useMutation } from '@apollo/client';
import { Button, Flex, Modal, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { allQuestionsDeletedEvent } from '../../../../events';
import { ClearQuestions } from './QuestionsMutation';

interface QuestionsClearModalProps {
	opened: boolean;
	onRequestClose: () => void;
}

export default function QuestionsClearModal({ opened, onRequestClose }: QuestionsClearModalProps) {
	const { t } = useTranslation();

	const [clearQuestions, { loading }] = useMutation(ClearQuestions);

	async function handleClearQuestions() {
		try {
			const { data } = await clearQuestions();
			const { success, errors } = data?.clearQuestions ?? {};

			if (!success || errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			onRequestClose();
			allQuestionsDeletedEvent.publish({});
			notifications.show({
				message: t('questions.settings.deleteAll.success'),
				color: 'teal',
			});
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	}

	return (
		<Modal
			centered
			size="sm"
			opened={opened}
			onClose={onRequestClose}
			title={<Text fz="sm">{t('questions.settings.deleteAll.modal.title')}</Text>}
		>
			<Stack mx="sm" mb="md" gap="sm">
				<Paper bg="dark.9" p="sm">
					<Stack>
						<Text fz="sm">
							{t('questions.settings.deleteAll.modal.description')}
						</Text>
					</Stack>
				</Paper>

				<Flex justify="flex-end" gap="xs">
					<Button
						size="xs"
						color="gray"
						variant="subtle"
						disabled={loading}
						onClick={onRequestClose}
					>
						{t('questions.settings.deleteAll.modal.cancel')}
					</Button>

					<Button
						size="xs"
						color="red.8"
						loading={loading}
						onClick={handleClearQuestions}
					>
						{t('questions.settings.deleteAll.modal.delete')}
					</Button>
				</Flex>
			</Stack>
		</Modal>
	);
}
