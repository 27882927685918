import { z } from 'zod';

import { i18n } from '../../../locales';

export const registerFormSchema = z.object({
	email: z.string()
		.email(i18n.t('auth.register.form.email.invalid'))
		.min(1, i18n.t('auth.register.form.email.required')),
});

export type RegisterFormInput = z.infer<typeof registerFormSchema>;

export const registerFormInitialValues: RegisterFormInput = {
	email: '',
};
