import { useMutation } from '@apollo/client';
import { ActionIcon, Avatar, Flex, Loader, Menu, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDots, IconShare3, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { useAccount } from '../../../contexts/account';
import { useProfile } from '../../../contexts/profile';
import { ReplyFieldsFragment } from '../../../contexts/replies/__generated__/RepliesQuery';
import { useShare } from '../../../contexts/share';
import { replyDeletedEvent } from '../../../events';
import generateInitials from '../../../helpers/generateInitials';
import paths from '../../../routes/paths';
import Link from '../../Link';
import { DeleteReply } from './ReplyMutation';

interface ReplyHeaderProps
	extends Pick<ReplyFieldsFragment, 'id' | 'createdAt' | 'content' | 'parent'> {}

const MAX_REPLY_LENGTH_FOR_SHARE = 80;
const MAX_QUESTION_LENGTH_FOR_SHARE = 28;

export default function ReplyHeader({
	id,
	parent,
	content,
	createdAt,
}: ReplyHeaderProps) {
	const { t } = useTranslation();

	const { share } = useShare();
	const { profile } = useProfile();
	const { account } = useAccount<'optional'>();

	const { username, fullName, profileImage } = profile || {};
	const isAuthenticatedUser = account?.username === profile?.username;

	const [opened, setOpened] = useState(false);
	const [deleteReply, { loading }] = useMutation(DeleteReply);

	const replyURL = new URL(
		generatePath(paths.reply, {
			username: username ?? '',
			replyId: id,
		}),
		window.location.origin,
	);

	const questionText = `${parent.content.length > MAX_REPLY_LENGTH_FOR_SHARE
		? `${parent.content.slice(0, MAX_REPLY_LENGTH_FOR_SHARE)}...`
		: parent.content}`;

	const replyText = `- ${content.length > MAX_QUESTION_LENGTH_FOR_SHARE
		? `${content.slice(0, MAX_QUESTION_LENGTH_FOR_SHARE)}...`
		: content}`;

	const shareText = `${questionText}%0A${replyText}`;

	const handleDelete = async () => {
		try {
			const { data } = await deleteReply({
				variables: { id },
			});

			const {
				errors,
				success,
			} = data?.deleteReply || {};

			if (!success || errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			replyDeletedEvent
				.publish({ replyId: id });
			notifications.show({
				message: t('replies.card.menu.delete.success'),
				color: 'green',
			});

			setOpened(false);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	return (
		<Flex justify="space-between" align="flex-start" gap="6">
			<Flex gap="sm" justify="flex-start" align="center">
				<Avatar
					size="md"
					radius="md"
					src={profileImage}
				>
					{generateInitials(fullName ?? '')}
				</Avatar>

				<Stack gap="2">
					<Flex justify="center" align="flex-end">
						<Text
							lh="1"
							fz="1rem"
							c="teal.6"
							maw="10rem"
							component={Link}
							ff="var(--ff-title)"
							to={generatePath(paths.profile, { username: username ?? '' })}
							style={{
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
						>
							{fullName}
						</Text>

						<Text fz="xs" c="gray" fw="200" lh="1">
							&nbsp;&#8226;&nbsp;
							<ReactTimeAgo
								timeStyle="twitter"
								date={new Date(createdAt)}
								updateInterval={[{ interval: 20, threshold: 60 }]}
							/>
						</Text>

					</Flex>

					<Text
						fz="xs"
						c="gray"
						fw="200"
						component={Link}
						to={generatePath(paths.profile, { username: username ?? '' })}
					>
						{`@${username}`}
					</Text>
				</Stack>
			</Flex>

			<Menu
				width={152}
				offset={4}
				shadow="md"
				opened={opened}
				onChange={setOpened}
				position="bottom-end"
			>
				<Menu.Target>
					<ActionIcon color="dark.2" variant="light">
						<Flex c="gray.1" justify="center" align="center">
							<IconDots size={14} stroke={1.5} />
						</Flex>
					</ActionIcon>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Item
						leftSection={<IconShare3 size="14" />}
						onClick={() => share({
							url: replyURL.toString(),
							text: shareText,
							title: t('replies.card.menu.share.title'),
						})}
					>
						{t('replies.card.menu.share.label')}
					</Menu.Item>

					{isAuthenticatedUser && (
						<Menu.Item
							color="red"
							onClick={handleDelete}
							closeMenuOnClick={false}
							leftSection={!loading ? <IconTrash size="14" /> : null}
						>
							{loading
								? (
									<Flex justify="center" align="center">
										<Loader size="12" color="red" />
									</Flex>
								)
								: t('replies.card.menu.delete.label')}
						</Menu.Item>
					)}
				</Menu.Dropdown>
			</Menu>
		</Flex>
	);
}
