import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Flex, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconChecks } from '@tabler/icons-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Alert from '../../../components/Alert';
import { useGuest } from '../../../contexts/guest';
import { useProfile } from '../../../contexts/profile';
import TextareaField from '../../../fields/TextareaField';
import useTextareaContent from '../../../hooks/useTextareaContent';
import { withoutAccount } from './ProfileQuestion.hoc';
import {
	createProfileQuestionFormInitialValues,
	CreateProfileQuestionFormInput,
	createProfileQuestionFormSchema,
	MAX_CONTENT_LENGTH,
} from './ProfileQuestion.utils';
import { CreateProfileQuestion } from './ProfileQuestionMutation';

const SUCCESS_TIMEOUT = 6_000;

const ProfileQuestion = withoutAccount(() => {
	const {
		content,
		inputId,
		submitTop,
		submitRef,
		setContent,
		submitHeight,
	} = useTextareaContent({ opened: true });

	const { t } = useTranslation();
	const { profile } = useProfile();
	const { updateGuestId } = useGuest();

	const [success, setSuccess] = useState(false);
	const [progress, setProgress] = useState(0);

	const [createQuestion] = useMutation(CreateProfileQuestion);

	const form = useForm<CreateProfileQuestionFormInput>({
		resolver: zodResolver(createProfileQuestionFormSchema),
		defaultValues: createProfileQuestionFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(async ({ content }) => {
		try {
			if (!profile) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			const { data } = await createQuestion({
				variables: {
					userId: profile.id,
					content: content.trim(),
				},
			});

			const {
				errors,
				guestId: updatedGuestId,
			} = data?.createQuestion || {};

			if (errors?.length || !updatedGuestId) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			form.reset();

			setContent('');
			setSuccess(true);
			setProgress(0);
			updateGuestId(updatedGuestId);

			setTimeout(() => setProgress(100), 100);
			setTimeout(() => setSuccess(false), SUCCESS_TIMEOUT);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	});

	return (
		<form noValidate onSubmit={onSubmitHandler}>
			<Paper w="100%" bg="dark.9" p="lg">
				<Stack gap="xs">
					<Stack gap="xs" pos="relative">
						<TextareaField
							autosize
							minRows={4}
							id={inputId}
							name="content"
							control={control}
							maxLength={MAX_CONTENT_LENGTH}
							onChange={e => setContent(e.currentTarget.value)}
							styles={{ input: { paddingBottom: submitHeight } }}
							placeholder={t('profile.form.content.placeholder')}
						/>

						<Flex
							pb="5"
							px="6"
							w="100%"
							pos="absolute"
							ref={submitRef}
							top={submitTop}
							align="flex-end"
							justify="space-between"
						>
							<Text fz=".75rem" fw="300" mb="-2" ml="1">
								{`${content.length}/${MAX_CONTENT_LENGTH}`}
							</Text>

							<Button
								size="xs"
								type="submit"
								variant="light"
								disabled={success}
								loading={isSubmitting}
							>
								{t('profile.form.submit')}
							</Button>
						</Flex>
					</Stack>

					{success && (
						<Alert
							color="teal"
							variant="light"
							progress={progress}
							icon={<IconChecks />}
							timeout={SUCCESS_TIMEOUT}
						>
							<Text fz="sm" c="teal" fw="normal">
								{t('profile.form.success')}
							</Text>
							<Text fz="xs" c="teal" fw="normal" mb="6">
								{t('profile.form.reminding')}
							</Text>
						</Alert>
					)}
				</Stack>
			</Paper>
		</form>
	);
});

export default ProfileQuestion;
