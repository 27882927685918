import { useMutation } from '@apollo/client';
import { Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Fragment, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import paths from '../paths';
import ResetPasswordForm, { ResetPasswordFormInput } from './ResetPasswordForm';
import { ResetPassword as ResetPasswordMutation } from './ResetPasswordMutation';
import ResetPasswordSuccess from './ResetPasswordSuccess';

export default function ResetPassword() {
	const { t } = useTranslation();

	const [email, setEmail] = useState<string>();
	const [emailSent, setEmailSent] = useState(false);

	const [resetPassword] = useMutation(ResetPasswordMutation);

	const handleSubmit = async ({ email }: ResetPasswordFormInput) => {
		try {
			const { data } = await resetPassword({
				variables: { input: { email } },
			});

			const {
				errors,
				success,
			} = data?.resetPassword || {};

			if (!success || !!errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			setEmail(email);
			setEmailSent(true);
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	const handleReset = () => {
		setEmailSent(false);
		setEmail(undefined);
	};

	const handleResendEmail = async () => {
		try {
			if (!email) {
				return;
			}

			const { data } = await resetPassword({
				variables: { input: { email } },
			});

			const {
				errors,
				success,
			} = data?.resetPassword || {};

			if (!success || !!errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			notifications.show({
				message: t('auth.resetPassword.emailResend.content'),
				title: t('auth.resetPassword.emailResend.title'),
				color: 'green',
			});
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	};

	if (emailSent) {
		return (
			<Fragment>
				<Helmet>
					<title>
						{t('auth.resetPassword.pageTitle')}
					</title>
				</Helmet>

				<ResetPasswordSuccess
					onRequestReset={handleReset}
					onRequestResend={handleResendEmail}
				/>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Helmet>
				<title>
					{t('auth.resetPassword.pageTitle')}
				</title>
			</Helmet>

			<Stack gap="0.25rem">
				<Paper w={324} bg="dark.9" p="lg">
					<Stack gap="md">
						<Stack gap="md" align="center">
							<Text ta="center" lh="1" fz="2rem" ff="var(--ff-title)">
								{t('auth.resetPassword.title')}
							</Text>
							<Text fz="sm" fw="200" ta="center">
								{t('auth.resetPassword.subtitle')}
							</Text>
						</Stack>

						<Divider />

						<ResetPasswordForm onSubmit={handleSubmit} />
					</Stack>
				</Paper>

				<Flex justify="center" pl="xs" gap=".25rem">
					<Text key="0" fz="xs" fw="200">
						<Trans
							i18nKey="auth.resetPassword.backToLogin"
							components={[
								<Text
									key="0"
									fz="xs"
									fw="500"
									c="teal.8"
									to={paths.login}
									component={Link}
								/>,
							]}
						/>
					</Text>
				</Flex>
			</Stack>
		</Fragment>
	);
}
