import { useMutation } from '@apollo/client';
import { LoadingOverlay } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../contexts/token';
import paths from '../paths';
import { Logout as LogoutMutation } from './LogoutMutation';

export default function Logout() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { removeToken } = useToken();
	const [logout] = useMutation(LogoutMutation);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await logout();
				const { success, errors } = data?.logout ?? {};

				if (!success || !!errors?.length) {
					notifications.show({
						message: t('common.error.unknown'),
						color: 'red',
					});
					return;
				}

				removeToken();
				navigate(paths.home, { replace: true });
			}
			catch (e) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
			}
		})();
	}, [logout, navigate, removeToken, t]);

	return <LoadingOverlay />;
}
