import { Paper, Stack } from '@mantine/core';
import { QuestionFieldsFragment } from '../../contexts/questions/__generated__/QuestionsQuery';
import QuestionContent from './QuestionContent';
import QuestionHeader from './QuestionHeader';
import QuestionParent from './QuestionParent';
import QuestionReplyForm from './QuestionReplyForm';

interface QuestionProps
	extends QuestionFieldsFragment {}

export default function Question({
	id,
	parent,
	content,
	createdAt,
}: QuestionProps) {
	return (
		<Paper w="100%" bg="dark.9" p="md">
			<Stack gap="0">
				<QuestionHeader id={id} createdAt={createdAt} />
				<Stack gap="xs">
					{parent && <QuestionParent {...parent} />}
					<QuestionContent content={content} />
					<QuestionReplyForm id={id} />
				</Stack>
			</Stack>
		</Paper>
	);
}
