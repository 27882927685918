import { z } from 'zod';
import { i18n } from '../../../locales';

export const loginFormSchema = z.object({
	identifier: z.string()
		.min(1, i18n.t('auth.login.form.identifier.required')),
	password: z.string()
		.min(1, i18n.t('auth.login.form.password.required')),
	remember: z.boolean().optional(),
});

export type LoginFormInput = z.infer<typeof loginFormSchema>;

export const loginFormInitialValues: LoginFormInput = {
	identifier: '',
	password: '',
	remember: false,
};
