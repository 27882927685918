import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Avatar, Button, Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAt, IconUser } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../../contexts/account';
import ImageField from '../../../fields/ImageField';
import TextareaField from '../../../fields/TextareaField';
import TextField from '../../../fields/TextField';
import useFormState from '../../../hooks/useFormState';
import { AccountErrorCode } from '../../../types';
import { withAccount } from './SettingsInformation.hoc';
import styles from './SettingsInformation.module.scss';

import {
	prepareUpdateInformationInput,
	UpdateInformationFormInput,
	updateInformationFormSchema,
} from './SettingsInformation.utils';
import { UpdateInformation } from './SettingsInformationMutation';

const SettingsInformation = withAccount(() => {
	const { t } = useTranslation();
	const { account } = useAccount();

	const [updateInformation] = useMutation(UpdateInformation);
	const [showUsernameAlert, setShowUsernameAlert] = useState(false);

	const form = useForm<UpdateInformationFormInput>({
		mode: 'onSubmit',
		resolver: zodResolver(updateInformationFormSchema),
		defaultValues: {
			username: account.username,
			fullName: account.fullName,
			biography: account.biography,
			profileImage: null,
		},
	});

	const {
		values,
		formRef,
		control,
		isDirty,
		handleSubmit,
		isSubmitting,
		initialValues,
	} = useFormState(form);

	const profileImageURL = useMemo(() =>
		values?.profileImage
			? URL.createObjectURL(values.profileImage)
			: account.profileImage, [account.profileImage, values.profileImage]);

	const onSubmitHandler = handleSubmit(async (values) => {
		try {
			const { data } = await updateInformation({
				variables: { input: prepareUpdateInformationInput(initialValues, values) },
			});

			const { account, errors } = data?.updateInformation || {};

			if (!account || errors?.length) {
				const usernameCannotBeUsedError
					= !!errors?.some(e => e.code === AccountErrorCode.UsernameCannotBeUsed);

				const usernameChangeNotAllowedError
					= !!errors?.some(e => e.code === AccountErrorCode.UsernameChangeNotAllowed);

				if (usernameCannotBeUsedError) {
					form.setError('username', {
						type: 'manual',
						message: t('settings.updateInformation.form.username.cannotBeUsed'),
					});
					return;
				}

				if (usernameChangeNotAllowedError) {
					form.setError('username', {
						type: 'manual',
						message: t('settings.updateInformation.form.username.changeNotAllowed'),
					});
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			notifications.show({
				message: t('settings.updateInformation.form.success'),
				color: 'teal',
			});
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	});

	useEffect(() => {
		if (values.username !== initialValues.username) {
			setShowUsernameAlert(true);
			return;
		}

		setShowUsernameAlert(false);
	}, [initialValues.username, values.username]);

	return (
		<Paper w="100%" bg="dark.9" p="md">
			<Stack gap="sm">
				<Text lh="1" fz="1.5rem" ff="var(--ff-title)">
					{t('settings.updateInformation.title')}
				</Text>

				<Divider />

				<form
					noValidate
					ref={formRef}
					onSubmit={onSubmitHandler}
				>
					<Stack gap="sm">
						<Flex gap="sm" className={styles.container}>
							<Stack gap="sm" flex="1">
								<TextField
									w="100%"
									size="sm"
									maxLength={32}
									name="fullName"
									inputMode="text"
									control={control}
									leftSection={<IconUser size={16} stroke={1.5} />}
									placeholder={t('settings.updateInformation.form.fullName.placeholder')}
								/>

								<TextField
									size="sm"
									maxLength={15}
									name="username"
									inputMode="text"
									control={control}
									leftSection={<IconAt size={16} stroke={1.5} />}
									placeholder={t('settings.updateInformation.form.username.placeholder')}
								/>

								<TextareaField
									rows={5}
									size="sm"
									maxLength={150}
									name="biography"
									inputMode="text"
									control={control}
									placeholder={t('settings.updateInformation.form.biography.placeholder')}
								/>
							</Stack>

							<Stack gap="xs" maw="10rem">
								<Avatar
									radius="md"
									size="10rem"
									src={profileImageURL}
								>
									<IconUser size="6rem" stroke={0.25} />
								</Avatar>

								<ImageField
									control={control}
									label="Görsel Seç"
									name="profileImage"
									accept="image/png,image/jpeg,image/jpg"
								/>
							</Stack>
						</Flex>

						<Stack gap="sm">
							<Alert
								color="blue"
								variant="light"
								display={
									showUsernameAlert
										? 'block'
										: 'none'
								}
							>
								<Text fz="14">
									{t('settings.updateInformation.alert.usernameTimeout')}
								</Text>
							</Alert>

							<Flex>
								<Button
									size="xs"
									type="submit"
									color="teal.8"
									disabled={!isDirty}
									loading={isSubmitting}
								>
									{t('settings.updateInformation.form.submit')}
								</Button>
							</Flex>
						</Stack>
					</Stack>
				</form>
			</Stack>
		</Paper>
	);
});

export default SettingsInformation;
