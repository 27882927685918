import { gql, TypedDocumentNode } from '@apollo/client';
import { CreateReplyMutation, CreateReplyMutationVariables } from './__generated__/QuestionReplyMutation';

export const CreateReply: TypedDocumentNode<
	CreateReplyMutation,
	CreateReplyMutationVariables
> = gql`
	mutation CreateReply($input: CreateReplyInput!) {
		createReply(input: $input) {
			reply {
				id
			}
			errors {
				code
			}
		}
	}
`;
