import { useMutation } from '@apollo/client';
import { Button, Flex, Modal, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { allRepliesDeletedEvent } from '../../../../events';
import { SettingsClearReplies } from './SettingsClearRepliesMutation';

interface SettingsClearRepliesModalProps {
	opened: boolean;
	onRequestClose: () => void;
}

export default function SettingsClearRepliesModal({
	opened,
	onRequestClose,
}: SettingsClearRepliesModalProps) {
	const { t } = useTranslation();

	const [clearReplies, { loading }] = useMutation(SettingsClearReplies);

	async function handleClearReplies() {
		try {
			const { data } = await clearReplies();
			const { success, errors } = data?.clearReplies ?? {};

			if (!success || errors?.length) {
				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			onRequestClose();
			allRepliesDeletedEvent.publish({});
			notifications.show({
				message: t('settings.accountSettings.clearReplies.modal.success'),
				color: 'teal',
			});
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	}

	return (
		<Modal
			centered
			size="sm"
			opened={opened}
			onClose={onRequestClose}
			title={<Text fz="sm">{t('settings.accountSettings.clearReplies.modal.title')}</Text>}
		>
			<Stack mx="sm" mb="md" gap="sm">
				<Paper bg="dark.9" px="sm" py="md">
					<Stack>
						<Text fz="sm">
							{t('settings.accountSettings.clearReplies.modal.description')}
						</Text>
					</Stack>
				</Paper>

				<Flex justify="flex-end" gap="xs">
					<Button
						size="xs"
						color="gray"
						variant="subtle"
						disabled={loading}
						onClick={onRequestClose}
					>
						{t('settings.accountSettings.clearReplies.modal.cancel')}
					</Button>

					<Button
						size="xs"
						color="red.8"
						loading={loading}
						onClick={handleClearReplies}
					>
						{t('settings.accountSettings.clearReplies.modal.delete')}
					</Button>
				</Flex>
			</Stack>
		</Modal>
	);
}
