import { Container, Stack } from '@mantine/core';
import { use100vh } from 'react-div-100vh';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from './Header';

export default function AppLayout() {
	const height = use100vh();

	return (
		<Container
			p="md"
			size="xs"
			display="flex"
			mih={height ?? '100vh'}
			style={{
				gap: '2rem',
				flexDirection: 'column',
			}}
		>
			<Stack w="100%" flex="1">
				<Header />
				<Outlet />
			</Stack>

			<Footer />
		</Container>
	);
}
