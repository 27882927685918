import {
	Checkbox,
	createTheme,
	LoadingOverlay,
	Paper,
	PasswordInput,
	Textarea,
	TextInput,
} from '@mantine/core';
import ToggleIcon from '../components/ToggleIcon';

const dark = createTheme({
	primaryColor: 'teal',
	primaryShade: 6,
	components: {
		Checkbox: Checkbox.extend({
			defaultProps: {
				color: 'teal.9',
			},
		}),
		TextInput: TextInput.extend({
			defaultProps: {
				spellCheck: false,
				autoComplete: 'off',
			},
		}),
		Textarea: Textarea.extend({
			defaultProps: {
				spellCheck: false,
				autoComplete: 'off',
			},
		}),
		Paper: Paper.extend({
			defaultProps: {
				radius: 'md',
			},
		}),
		LoadingOverlay: LoadingOverlay.extend({
			defaultProps: {
				visible: true,
				loaderProps: {
					size: 'md',
					color: 'teal.7',
				},
				overlayProps: {
					bg: 'dark.7',
				},
			},
		}),
		PasswordInput: PasswordInput.extend({
			defaultProps: {
				spellCheck: false,
				visibilityToggleIcon: ToggleIcon,
			},
		}),
	},
});

export default dark;
