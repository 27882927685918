import { useLocalStorage } from '@mantine/hooks';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { QuestionsProvider } from '../../contexts/questions';
import { QuestionFieldsFragment } from '../../contexts/questions/__generated__/QuestionsQuery';
import { PageInfo, SortOrder } from '../../types';
import QuestionsEmpty from './QuestionsEmpty';
import QuestionsFailed from './QuestionsFailed';
import QuestionsLayout from './QuestionsLayout';
import QuestionsList from './QuestionsList';
import QuestionsPagination from './QuestionsPagination';
import QuestionsSkeleton from './QuestionsSkeleton';

const DEFAULT_LIMIT = 20;

enum QuestionsParam {
	Page = 'p',
}

export default function Questions() {
	const { t } = useTranslation();
	const [params, setParams] = useSearchParams();

	const [order, setOrder] = useLocalStorage({
		key: 'app:question-order',
		defaultValue: SortOrder.Asc,
		getInitialValueInEffect: false,
	});

	const page = Number(params.get(QuestionsParam.Page)) || 1;
	const offset = (page - 1) * DEFAULT_LIMIT;

	function handleOrderChange(order: SortOrder) {
		setTimeout(() =>
			window.scrollTo({ top: 0, behavior: 'smooth' }), 300);

		setOrder(order);
		setParams((params) => {
			params.set(QuestionsParam.Page, '1');
			return params;
		});
	}

	function handlePageChange(page: number) {
		setTimeout(() =>
			window.scrollTo({ top: 0, behavior: 'smooth' }), 300);

		setParams((params) => {
			params.set(QuestionsParam.Page, String(page));
			return params;
		});
	}

	function handleCompleted(edges: QuestionFieldsFragment[], pageInfo: PageInfo) {
		if (edges.length === 0 && pageInfo.total > 0) {
			const offset = (Math.floor(pageInfo.total / pageInfo.limit) - 1) * pageInfo.limit;
			const page = offset < 0 ? 1 : Math.floor(pageInfo.total / pageInfo.limit);

			setParams((params) => {
				params.set(QuestionsParam.Page, String(page));
				return params;
			});
		}
	}

	return (
		<Fragment>
			<Helmet>
				<title>{t('questions.pageTitle')}</title>
			</Helmet>

			<QuestionsProvider
				order={order}
				offset={offset}
				limit={DEFAULT_LIMIT}
				onCompleted={handleCompleted}
			>
				{({
					error,
					loading,
					pageInfo,
					questions,
				}) => {
					if (loading && !error) {
						return (
							<QuestionsLayout
								order={order}
								onOrderChange={handleOrderChange}
							>
								<QuestionsSkeleton />
							</QuestionsLayout>
						);
					}

					if (!loading && error) {
						return (
							<QuestionsLayout
								order={order}
								onOrderChange={handleOrderChange}
							>
								<QuestionsFailed />
							</QuestionsLayout>
						);
					}

					if (!loading && !questions.length && !pageInfo.total) {
						return (
							<QuestionsLayout
								order={order}
								onOrderChange={handleOrderChange}
							>
								<QuestionsEmpty />
							</QuestionsLayout>
						);
					}

					return (
						<QuestionsLayout
							order={order}
							onOrderChange={handleOrderChange}
						>
							<QuestionsList />
							<QuestionsPagination onChange={handlePageChange} />
						</QuestionsLayout>
					);
				}}
			</QuestionsProvider>
		</Fragment>
	);
}
