import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Flex, Modal, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconLock } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../../../contexts/token';
import PasswordField from '../../../../fields/PasswordField';
import { AuthErrorCode } from '../../../../types';
import paths from '../../../paths';
import {
	deleteAccountFormInitialValues,
	DeleteAccountFormInput,
	deleteAccountFormSchema,
} from './SettingsDeleteAccountModal.utils';
import { SettingsDeleteAccount } from './SettingsDeleteAccountMutation';

interface SettingsClearQuestionsModalProps {
	opened: boolean;
	onRequestClose: () => void;
}

export default function SettingsDeleteAccountModal({
	opened,
	onRequestClose,
}: SettingsClearQuestionsModalProps) {
	const navigate = useNavigate();

	const { t } = useTranslation();
	const { removeToken } = useToken();

	const [deleteAccount] = useMutation(SettingsDeleteAccount);

	const form = useForm<DeleteAccountFormInput>({
		resolver: zodResolver(deleteAccountFormSchema),
		defaultValues: deleteAccountFormInitialValues,
	});

	const { control, handleSubmit, formState: { isSubmitting } } = form;
	const onSubmitHandler = handleSubmit(async ({ password }) => {
		try {
			const { data } = await deleteAccount({ variables: { password } });
			const { success, errors } = data?.deleteAccount ?? {};

			if (!success || errors?.length) {
				if (errors?.some(e => e.code === AuthErrorCode.InvalidCredentials)) {
					form.setError('password', {
						type: 'manual',
						message: t('settings.accountSettings.deleteAccount.modal.form.password.invalid'),
					});
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			navigate(paths.home);
			removeToken();

			onRequestClose();
			notifications.show({
				message: t('settings.accountSettings.deleteAccount.modal.success'),
				color: 'teal',
			});
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	});

	return (
		<Modal
			centered
			size="sm"
			opened={opened}
			onClose={onRequestClose}
			title={<Text fz="sm">{t('settings.accountSettings.deleteAccount.modal.title')}</Text>}
		>
			<form noValidate onSubmit={onSubmitHandler}>
				<Stack mx="sm" mb="md" gap="sm">

					<Paper bg="dark.9" px="sm" py="md">
						<Stack gap="sm">
							<Text fz="sm">
								{t('settings.accountSettings.deleteAccount.modal.description')}
							</Text>

							<Divider />

							<PasswordField
								size="sm"
								maxLength={20}
								name="password"
								control={control}
								autoComplete="current-password"
								leftSection={<IconLock size={16} stroke={1.5} />}
								placeholder={t('settings.accountSettings.deleteAccount.modal.form.password.placeholder')}
							/>
						</Stack>
					</Paper>

					<Flex justify="flex-end" gap="xs">
						<Button
							size="xs"
							color="gray"
							variant="subtle"
							disabled={isSubmitting}
							onClick={onRequestClose}
						>
							{t('settings.accountSettings.deleteAccount.modal.cancel')}
						</Button>

						<Button
							size="xs"
							color="red.8"
							type="submit"
							loading={isSubmitting}
						>
							{t('settings.accountSettings.deleteAccount.modal.delete')}
						</Button>
					</Flex>
				</Stack>
			</form>
		</Modal>
	);
}
