import { createContext } from 'react';

export interface SharePayload {
	url: string;
	text: string;
	title: string;
}

export interface ShareContextType {
	share: (payload: SharePayload) => void;
}

export default createContext<ShareContextType | null>(null);
