import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { RepliesProvider } from '../../contexts/replies';
import RepliesEmpty from './RepliesEmpty';
import RepliesFailed from './RepliesFailed';
import RepliesList from './RepliesList';
import RepliesPagination from './RepliesPagination';
import RepliesSkeleton from './RepliesSkeleton';

const DEFAULT_LIMIT = 20;

enum RepliesParam {
	Page = 'p',
}

export default function Replies() {
	const { t } = useTranslation();
	const { username } = useParams<'username'>();

	const [params, setParams] = useSearchParams();

	const page = Number(params.get(RepliesParam.Page)) || 1;
	const offset = (page - 1) * DEFAULT_LIMIT;

	return (
		<Fragment>
			<Helmet>
				<title>
					{t('profile.pageTitle', { username: username ?? '' })}
				</title>
			</Helmet>

			<RepliesProvider
				limit={DEFAULT_LIMIT}
				offset={offset}
				username={username!}
				onCompleted={(edges, pageInfo) => {
					if (edges.length === 0 && pageInfo.total > 0) {
						const offset = (Math.floor(pageInfo.total / pageInfo.limit) - 1) * pageInfo.limit;
						const page = offset < 0 ? 1 : Math.floor(pageInfo.total / pageInfo.limit);

						setParams((params) => {
							params.set(RepliesParam.Page, String(page));
							return params;
						});
					}
				}}
			>
				{({
					error,
					loading,
					replies,
					pageInfo,
				}) => {
					if (loading && !error)
						return <RepliesSkeleton />;

					if (!loading && error)
						return <RepliesFailed />;

					if (!loading && !replies.length && !pageInfo.total)
						return <RepliesEmpty />;

					return (
						<Fragment>
							<RepliesList />
							<RepliesPagination
								onChange={(page) => {
									setTimeout(() =>
										window.scrollTo({ top: 0, behavior: 'smooth' }), 300);
									setParams((params) => {
										params.set(RepliesParam.Page, String(page));
										return params;
									});
								}}
							/>
						</Fragment>
					);
				}}
			</RepliesProvider>
		</Fragment>
	);
}
