import { Avatar, Button, Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { IconShare3, IconUser } from '@tabler/icons-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import Link from '../../../components/Link';
import { useAccount } from '../../../contexts/account';
import { useProfile } from '../../../contexts/profile';
import { useShare } from '../../../contexts/share';
import paths from '../../../routes/paths';
import ProfileCardSkeleton from './ProfileCardSkeleton';

export default function ProfileCard() {
	const { t } = useTranslation();
	const { share } = useShare();
	const { account } = useAccount<'optional'>();
	const { profile, replyCount, loading } = useProfile();

	if (loading || !profile) {
		return <ProfileCardSkeleton />;
	}

	const {
		username,
		fullName,
		biography,
		profileImage,
	} = profile;

	const isPrivate = account?.username === username;
	const profileURL = new URL(
		generatePath(paths.profile, { username }),
		window.location.origin,
	);

	return (
		<Paper w="100%" bg="dark.9" p="lg">
			<Stack>
				<Flex gap="sm">
					<Avatar
						size="7rem"
						radius="md"
						src={profileImage}
					>
						<IconUser size="5rem" stroke={0.25} />
					</Avatar>

					<Stack w="100%" gap="xs" justify="space-between">
						<Stack gap="6px">
							<Text
								lh="1"
								fz="2rem"
								c="teal.6"
								component={Link}
								ff="var(--ff-title)"
								to={generatePath(paths.profile, { username })}
								style={{
									wordBreak: 'break-word',
									overflowWrap: 'break-word',
								}}
							>
								{fullName}
							</Text>
							<Text
								fz="md"
								c="gray"
								fw="200"
								component={Link}
								to={generatePath(paths.profile, { username })}
							>
								{`@${username}`}
							</Text>
						</Stack>

						<Flex gap="xs">
							<Button
								size="xs"
								variant="light"
								component={Link}
								to={generatePath(paths.profile, { username })}
							>
								{replyCount}
								<Text inherit ml=".25rem" fw="400">
									{t('profile.replyCount')}
								</Text>
							</Button>

							<Button
								size="xs"
								px="0.36rem"
								variant="light"
								onClick={() => share({
									url: profileURL.toString(),
									text: !isPrivate
										? t('common.share.profile.public', { username })
										: t('common.share.profile.private', { username }),
									title: t('common.share.profile.title'),
								})}
							>
								<IconShare3 size={17} stroke={1.5} />
							</Button>
						</Flex>
					</Stack>
				</Flex>

				{biography && (
					<Fragment>
						<Divider />
						<Text fz="xs" fw="400">
							{biography}
						</Text>
					</Fragment>
				)}
			</Stack>
		</Paper>
	);
}
