import { useDisclosure } from '@mantine/hooks';
import { Fragment, PropsWithChildren, useMemo, useState } from 'react';
import ShareContext, { SharePayload } from './ShareContext';
import ShareModal from './ShareModal';

interface ShareProviderProps
	extends PropsWithChildren<any> {}

export default function ShareProvider({ children }: ShareProviderProps) {
	const [opened, { open, close }] = useDisclosure();
	const [payload, setPayload] = useState<SharePayload | null>(null);

	const contextValue = useMemo(() => ({
		share: (payload: SharePayload) => {
			setPayload(payload);
			open();
		},
	}), [open]);

	return (
		<Fragment>
			<ShareContext.Provider value={contextValue}>
				{children}
			</ShareContext.Provider>

			{opened && payload && (
				<ShareModal
					opened
					payload={payload}
					onRequestClose={close}
				/>
			)}
		</Fragment>
	);
}
