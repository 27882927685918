import { gql, TypedDocumentNode } from '@apollo/client';
import {
	CreateProfileQuestionMutation,
	CreateProfileQuestionMutationVariables,
} from './__generated__/ProfileQuestionMutation';

export const CreateProfileQuestion: TypedDocumentNode<
	CreateProfileQuestionMutation,
	CreateProfileQuestionMutationVariables
> = gql`
	mutation CreateProfileQuestion($userId: ID!, $content: String! ) {
		createQuestion(input: { userId: $userId, content: $content }) {
			guestId
			errors {
				code
			}
		}
	}
`;
