import { gql, TypedDocumentNode } from '@apollo/client';
import { RegisterMutation, RegisterMutationVariables } from './__generated__/RegisterMutation';

export const Register: TypedDocumentNode<RegisterMutation, RegisterMutationVariables> = gql`
    mutation Register($input: RegisterInput!) {
        register(input: $input) {
            success
            errors {
                code
            }
        }
    }
`;
