import { Button, Stack, Text } from '@mantine/core';
import { IconBug } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import SimpleLayout from '../../layouts/SimpleLayout';

export default function ExceptionFallback() {
	const { t } = useTranslation();
	return (
		<SimpleLayout>
			<Stack
				w="100%"
				py="2rem"
				pt="2rem"
				align="center"
				justify="center"
			>
				<IconBug size="4rem" stroke={0.75} />

				<Stack gap="md" justify="center" align="center">
					<Stack gap="xs" align="center">
						<Text ta="center" lh="1" fz="1.6rem" ff="var(--ff-title)">
							{t('layout.errorFallback.title')}
						</Text>
						<Text fz="xs" fw="200" ta="center" px="6rem">
							{t('layout.errorFallback.subtitle')}
						</Text>
					</Stack>

					<Button
						size="xs"
						variant="light"
						onClick={() => window.location.reload()}
					>
						{t('layout.errorFallback.refresh')}
					</Button>
				</Stack>
			</Stack>
		</SimpleLayout>
	);
}
