import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { IconLock } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToken } from '../../../contexts/token';
import PasswordField from '../../../fields/PasswordField';
import useFormState from '../../../hooks/useFormState';
import { AccountErrorCode } from '../../../types';
import {
	updatePasswordFormInitialValues,
	UpdatePasswordFormInput,
	updatePasswordFormSchema,
} from './SettingsPassword.utils';
import { UpdatePassword } from './SettingsPasswordMutation';

export default function SettingsPassword() {
	const { t } = useTranslation();
	const { setToken } = useToken();

	const [updatePassword] = useMutation(UpdatePassword);

	const form = useForm<UpdatePasswordFormInput>({
		resolver: zodResolver(updatePasswordFormSchema),
		defaultValues: updatePasswordFormInitialValues,
	});

	const {
		control,
		isDirty,
		formRef,
		handleSubmit,
		isSubmitting,
	} = useFormState(form);

	const onSubmitHandler = handleSubmit(async ({
		currentPassword,
		newPassword,
	}) => {
		try {
			const { data } = await updatePassword({
				variables: {
					input: {
						currentPassword,
						newPassword,
					},
				},
			});

			const {
				errors,
				accessToken,
			} = data?.updatePassword ?? {};

			if (!accessToken || !!errors?.length) {
				const currentPasswordError = !!errors
					?.some(e => e.code === AccountErrorCode.InvalidCurrentPassword);

				if (currentPasswordError) {
					form.setError('currentPassword', {
						type: 'manual',
						message: t('settings.updatePassword.form.currentPassword.invalid'),
					});
					return;
				}

				notifications.show({
					message: t('common.error.unknown'),
					color: 'red',
				});
				return;
			}

			setToken(accessToken);
			notifications.show({
				message: t('settings.updatePassword.form.success'),
				color: 'teal',
			});
		}
		catch (e) {
			notifications.show({
				message: t('common.error.unknown'),
				color: 'red',
			});
		}
	});

	return (
		<Paper w="100%" bg="dark.9" p="md">
			<Stack gap="sm">
				<Text lh="1" fz="1.5rem" ff="var(--ff-title)">
					{t('settings.updatePassword.title')}
				</Text>

				<Divider />

				<form
					noValidate
					ref={formRef}
					onSubmit={onSubmitHandler}
				>
					<Stack gap="sm" w="min(100%, 19rem)">
						<PasswordField
							size="sm"
							maxLength={20}
							control={control}
							name="currentPassword"
							autoComplete="current-password"
							leftSection={<IconLock size={16} stroke={1.5} />}
							placeholder={t('settings.updatePassword.form.currentPassword.placeholder')}
						/>

						<Divider />

						<PasswordField
							size="sm"
							maxLength={20}
							control={control}
							name="newPassword"
							autoComplete="new-password"
							leftSection={<IconLock size={16} stroke={1.5} />}
							placeholder={t('settings.updatePassword.form.newPassword.placeholder')}
						/>

						<PasswordField
							size="sm"
							maxLength={20}
							control={control}
							name="confirmPassword"
							autoComplete="new-password"
							leftSection={<IconLock size={16} stroke={1.5} />}
							placeholder={t('settings.updatePassword.form.confirmPassword.placeholder')}
						/>

						<Divider />

						<Flex>
							<Button
								size="xs"
								type="submit"
								color="teal.8"
								disabled={!isDirty}
								loading={isSubmitting}
							>
								{t('settings.updatePassword.form.submit')}
							</Button>
						</Flex>
					</Stack>
				</form>
			</Stack>
		</Paper>
	);
}
