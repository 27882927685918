import { Divider, Stack } from '@mantine/core';
import { Outlet, useParams } from 'react-router-dom';
import { ProfileProvider } from '../../contexts/profile';
import ProfileCard from './ProfileCard';
import ProfileControl from './ProfileControl';
import ProfileLinks from './ProfileLinks';
import ProfileQuestion from './ProfileQuestion';

export default function ProfileLayout() {
	const { username } = useParams<'username'>();

	return (
		<ProfileProvider username={username!}>
			<ProfileControl>
				<Stack w="100%">
					<ProfileCard />
					<ProfileLinks />
					<ProfileQuestion />

					<Divider />
					<Outlet />
				</Stack>
			</ProfileControl>
		</ProfileProvider>
	);
}
